import React from 'react';
import { Calendar } from 'primereact/calendar';

export const JMT_FIELD_DATEBOX = ({id, caption, value, setter}) => {

    return (
        <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                <Calendar 
                    dateFormat="yy-mm-dd" 
                    style={
                        {
                            border:'0px', 
                            height: '22px',
                            width:'100%', 
                            padding:'2px', 
                            backgroundColor:'lavender'
                        }
                    } 
                    id={id} 
                    showButtonBar 
                    value={value} 
                    onChange={(e) => setter(e.value)}
                    showIcon
                ></Calendar>
            </div>
        </div>
    );
}

