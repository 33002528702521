import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';

import { AXIOS                   } from './axios'
import { JMT_API_BUTTON          } from './jmt_api_button'
import { JMT_API_SPAN          } from './jmt_api_span'


export const JMT_XLSX_DIALOG = ({title, endpoint, payload, enabled=true, layout="inline"}) => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
        set_axios_trigger(new Date());
    }

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1])
        const mimeString = dataURI
          .split(',')[0]
          .split(':')[1]
          .split(';')[0]
      
        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
      
        // write the ArrayBuffer to a blob, and you're done
        const bb = new Blob([ab], { type: mimeString })
        return bb
    }

    const axios_success = (res) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.base64_str}`
        const pdfBlob = dataURItoBlob(linkSource)
        /**
         * Internet Explorer stuff!
         */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(pdfBlob, `${Date.now()}.pdf`)
          return
        }
        const url = window.URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${title} ${Date.now()}.xlsx`)
        document.body.appendChild(link)
        link.click()    
        link.remove()
        setDisplayBasic(false);
        return 
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={payload} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            style={{ width:'50vw'}}
            onHide={() => setDisplayBasic(false)}
        >
            <ProgressBar mode="indeterminate" style={{ height: '16px' }}></ProgressBar>
        </Dialog>
        {
            layout === "inline" 
            ?
            <JMT_API_BUTTON icon="pi pi-table" caption={title} api={endpoint} onclick={handlePopup} color="secondary" enabled={enabled}/>
            :
            <JMT_API_SPAN icon="pi pi-table" api={endpoint} onclick={handlePopup} color="secondary" enabled={enabled}/>
        }
        </>
    );
}
