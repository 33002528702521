import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                  } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

import { POPUP_USER_LOOKUP              } from './lookup';
import { POPUP_USER_DELETE              } from './user_delete';
import { POPUP_USER_CREATE              } from './user_create';
import { POPUP_USER_TIMESHEETS          } from './user_timesheets';
import { POPUP_USER_RESET_PASSWORD      } from './user_reset_password';
import { POPUP_USER_UPDATE              } from './user_update';
import { POPUP_USER_ENDPOINT_BUILD      } from './user_endpoint_build';
import { POPUP_USER_ENDPOINT_UPDATE     } from './user_endpoint_update';
import { POPUP_USER_ROLE_ENDPOINT_UPDATE } from './user_role_endpoint_update';


export const Page_User = () => {
    const localSName = "Afromix_UserName";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [Username, setUsername] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "user":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, Username);
        handleRefresh()
    }, [Username]);

    const [date,            setDate             ] = useState("");

    var yesterday = new Date();
    yesterday.setDate(new Date().getDate()-1);
    yesterday = yesterday.toISOString().split('T')[0];
    var today =  new Date().toISOString().split('T')[0];

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/user/get/read' 
                payload={{
                    "username":Username
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="User">
                    <POPUP_USER_LOOKUP id="TagNo" value={Username} setter={setUsername} caption="Username"/>
                    <JMT_FIELD_LABEL id="Name" value={axios_data.user[0]?.Name} caption="Name" />
                    <JMT_FIELD_LABEL id="Surname" value={axios_data.user[0]?.Surname} caption="Surname" />
                    <JMT_FIELD_LABEL id="JobTitle" value={axios_data.user[0]?.JobTitle} caption="JobTitle" />
                    <JMT_FIELD_LABEL id="Dept" value={axios_data.user[0]?.Dept} caption="Dept" />
                    <JMT_FIELD_LABEL id="Cell" value={axios_data.user[0]?.Cell} caption="Cell" />
                    <JMT_FIELD_LABEL id="Email" value={axios_data.user[0]?.Email} caption="Email" />
                    <JMT_FIELD_LABEL id="TelegramID" value={axios_data.user[0]?.TelegramID} caption="TelegramID" />
                    <br/>
                    <POPUP_USER_CREATE/>
                    <POPUP_USER_UPDATE username={Username} data={axios_data.user[0]} refresh={handleRefresh}/>
                    <POPUP_USER_RESET_PASSWORD username={Username}/>
                    <POPUP_USER_DELETE username={Username} data={axios_data.user[0]} setter={setUsername}/>
                    <POPUP_USER_ENDPOINT_BUILD UserName={Username} UserData={axios_data}/>
                    <POPUP_USER_ENDPOINT_UPDATE UserName={Username} UserData={axios_data}/>
                    <POPUP_USER_ROLE_ENDPOINT_UPDATE UserName={Username} UserData={axios_data}/>
                    <JMT_PDF_DIALOG title="Datasheet" endpoint="/user/pdf/user/access" payload={{"username": Username}}/>
                </Panel>
                <br/>
                <Panel header="User Timesheets" >
                    <JMT_TIMESHEET username={Username} onDate={today} />
                    <JMT_TIMESHEET username={Username} onDate={yesterday} />
                    <br/>
                    <br/>
                    <POPUP_USER_TIMESHEETS username={Username}/>
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
