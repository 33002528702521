import React, { useState } from 'react';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_PALLET_ADD = ({ setter }) => {
    const endpoint = "/pallet/set/add";
    const title = "Pallet Add"
    const [axios_trigger, set_axios_trigger] = useState("");


    const handlePopup = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        setter(res.data.pallet_id);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint}
                payload={{
                    "branch": "JHB"
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup} color="warning"/>
        </>
    );
}





