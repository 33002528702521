import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTBOX                } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN               } from '../../components/jmt_field_dropdown'
import { JMT_FIELD_DATEBOX              } from '../../components/jmt_field_datebox.jsx';


export const POPUP_QUEUE_NO_ANSWER = ({ queue_id, data, refresh }) => {
    const localSName = "LCBI_PreferredDay";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const endpoint = "/queue/set/no/answer";
    const title = "No Answer"
    const [displayBasic, setDisplayBasic] = useState(false); 
    const [axios_trigger, set_axios_trigger] = useState("");

    const [Note, setNote] = useState("");
    const [PreferredDay, setPreferredDay] = useState("");

    const [CustomerID, setCustomerID] = useState("");
    const [VenueType, setVenueType] = useState("");
    const [Area, setArea] = useState("");
    const [Tel, setTel] = useState("");
    const [Tel2, setTel2] = useState("");
    const [Fax, setFax] = useState("");
    const [Fax2, setFax2] = useState("");
    const [Email, setEmail] = useState("");
    const [GroupHeadOffice, setGroupHeadOffice] = useState("");
    const [GroupAccount, setGroupAccount] = useState("");
    const [Contact_Person, setContact_Person] = useState("");
    const [Rep, setRep] = useState("")

    useEffect(() => {
        setCustomerID(data[0]?.CustomerID)
        setTel(data[0]?.Tel)
        setTel2(data[0]?.Tel2)
        setFax(data[0]?.Fax)
        setFax2(data[0]?.Fax2)
        setEmail(data[0]?.Email)
        setVenueType(data[0]?.VenueType)
        setRep(data[0]?.Rep)
        setGroupHeadOffice(data[0]?.GroupHeadOffice) 
        setGroupAccount(data[0]?.GroupAccount)
        setArea(data[0]?.Area)
        setContact_Person(data[0]?.Contact_Person)
        // setPreferredDay(data[0]?.PreferredDay)
        localStorage.setItem(localSName, PreferredDay);
    }, [data]);

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "queue_id": queue_id,
                "customer_id": CustomerID,
                "note":Note,
                "preferred_day":PreferredDay.PreferredDay,
                "tel": Tel,
                "tel_2": Tel2,
                "fax": Fax,
                "fax_2": Fax2,
                "email": Email,
                "venue_type": VenueType,
                "rep": Rep,
                "group_head_office": GroupHeadOffice,
                "group_account": GroupAccount,
                "area": Area,
                "contact_person": Contact_Person,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="QueueID" value={queue_id} caption="QueueID"/>

            <JMT_FIELD_TEXTBOX id="VenueType" value={VenueType} setter={setVenueType} caption="VenueType"/>
            <JMT_FIELD_TEXTBOX id="Area" value={Area} setter={setArea} caption="Area"/>
            <JMT_FIELD_TEXTBOX id="Tel" value={Tel} setter={setTel} caption="Tel"/>
            <JMT_FIELD_TEXTBOX id="Tel2" value={Tel2} setter={setTel2} caption="Tel2"/> 
            <JMT_FIELD_TEXTBOX id="Fax" value={Fax} setter={setFax} caption="Fax"/>
            <JMT_FIELD_TEXTBOX id="Fax2" value={Fax2} setter={setFax2} caption="Fax2"/>


            <JMT_FIELD_TEXTBOX id="Email" value={Email} setter={setEmail} caption="Email"/>
            <JMT_FIELD_TEXTBOX id="GroupHeadOffice" value={GroupHeadOffice} setter={setGroupHeadOffice} caption="GroupHeadOffice"/>
            <JMT_FIELD_TEXTBOX id="GroupAccount" value={GroupAccount} setter={setGroupAccount} caption="GroupAccount"/>
            <JMT_FIELD_TEXTBOX id="Contact_Person" value={Contact_Person} setter={setContact_Person} caption="Contact_Person"/>
            <JMT_FIELD_TEXTBOX id="Rep" value={Rep} setter={setRep} caption="Rep"/>
            <br/>
            <JMT_FIELD_DROPDOWN id="PreferredDay" list={[
                {PreferredDay: 'No Day'},
                {PreferredDay: 'Monday'},
                {PreferredDay: 'Tuesday'},
                {PreferredDay: 'Wednesday'},
                                    {PreferredDay: 'Thursday'},
                                    {PreferredDay: 'Friday'},
                                ]} value={PreferredDay} setter={setPreferredDay} caption="PreferredDay" />
            <JMT_FIELD_TEXTBOX id="Note" value={Note} setter={setNote} caption="Note"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />
            {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
