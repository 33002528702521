import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_DROPDOWN             } from '../../components/jmt_field_dropdown';



export const POPUP_BI_SALES_TO_DATE = () => {
    const endpoint = "/pastel/bi/sales/to/date";
    const title = "BI Sales to Date"
    const [value, setvalue] = useState("");
    const [s_group, sets_group] = useState("");
    const [period, setperiod] = useState("");
    const [category, setcategory] = useState("");
    const [description, setdescription] = useState("");
    const [name, setname] = useState("");
    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "data":[]
    });

    const handleClick = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    // "value": value,
                    "value": "Qty",
                    // "s_group": s_group,
                    "s_group": "Customer",
                    // "period": period,
                    "period": "Year",
                    // "category": category,
                    "category": "TOP",
                    // "description": description,
                    "description": "Monin Rose 250ml",
                    // "name": name,
                    "name": "CC TRADE 252 /ICEBURG",
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />        
            <Dialog 
                header={title} 
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <br/>
                <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleClick} />
                <pre>{JSON.stringify(axios_data, null, 2) }</pre>
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

