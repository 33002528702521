import React, { useState, useEffect } from 'react';

import { Dropdown } from 'primereact/dropdown';

export const JMT_INPUTGROUP_SORTER = ({cols, sortField, setSortField, sortOrder}) => {

    const [sortIcon, setSortIcon] = useState("")

    useEffect(()=>{
        setSortIcon("pi pi-sort-alt");
        if(sortOrder===1){
            setSortIcon("pi pi-sort-amount-up-alt");
        }
        if(sortOrder===-1){
            setSortIcon("pi pi-sort-amount-down");
        }
    }, [sortOrder]);
    
    const selectedItemTemplate = (option, props) => {
        if (option) {
            return (
                <div>
                    <div>{option}</div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const itemOptionTemplate = (option) => {
        return (
            <div>
                <div>{option}</div>
            </div>
        );
    }

    return (
        <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    Sort By
                </span>
                <Dropdown 
                    value={sortField} 
                    options={cols} 
                    onChange={(e) => setSortField(e.value)} 
                    optionLabel="name" 
                    showClear 
                    valueTemplate={selectedItemTemplate} 
                    itemTemplate={itemOptionTemplate} 
                />     
                <span className="p-inputgroup-addon">
                    <i className={sortIcon}></i>
                </span>
            </div>
        </div>
    );
}

