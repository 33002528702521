import React, { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const authTokenStore = localStorage.getItem("afromixCrmAuthToken");
    const authUserStore = localStorage.getItem("afromixCrmAuthUser");
    const authAPISStore = localStorage.getItem("afromixCrmAuthAPIS");
    const authScreensStore = localStorage.getItem("afromixCrmAuthScreens");

    // console.log(authAPISStore);
    const [authToken, setAuthToken] = useState(authTokenStore);
    const [authUser, setAuthUser] = useState(authUserStore);
    const [authAPIS, setAuthAPIS] = useState(authAPISStore === null ? [] : authAPISStore);
    const [authScreens, setAuthScreens] = useState(authScreensStore === null ? [] : authScreensStore);

    return (        
        <AuthContext.Provider value={{ 
            authTokenObj: [authToken, setAuthToken], 
            authUserObj: [authUser, setAuthUser],  
            authAPISObj: [authAPIS, setAuthAPIS],  
            authScreensObj: [authScreens, setAuthScreens],  
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;