import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                                      } from '../../components/axios'
import { JMT_FIELD_LABEL                            } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                             } from '../../components/jmt_api_button';
import { JMT_FIELD_TEXTAREA                         } from '../../components/jmt_field_textarea.jsx';


export const POPUP_WAREHOUSE_PANEL_UID_NOTES = ({uid, notes, loading, refresh}) => {
    const endpoint = "/warehouse/note/add";
    const title = "Add Note"

    const [Note, setNote] = useState();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({});

    const handlePopup = () => {
        setDisplayBasic(true);    
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        refresh();
        setNote("");
        setDisplayBasic(false);
        set_axios_loading(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "uid":uid,
                "note":Note,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
            <h4>Warehouse Notes</h4>
            <DataTable 
                style={{padding: '0px'}}  
                value={notes} 
                loading={axios_loading} 
                dataKey="StockID" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
                >
                <Column header="UserName" field="UserName"/>
                <Column header="Logtime" field="Logtime"/>
                <Column header="StatusFrom" field="StatusFrom"/>
                <Column header="StatusTo" field="StatusTo"/>
                <Column header="Note" field="Note"/>
            </DataTable>
            <br/>
            <JMT_FIELD_TEXTAREA id="Note" value={Note} setter={setNote} caption="Note"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
        </>
    );
}



