import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';


export const POPUP_PALLET_EMPTY = ({pallet_id, location_id, lineCount, refresh}) => {
    const endpoint = "/pallet/set/empty";
    const title = "Pallet Empty"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_data, set_axios_data] = useState({
        "data": [], 
    });

    const [LocationID, setLocationID] = useState(location_id);

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const axios_success = (res) => {
        console.log("refresh");
        set_axios_data(res.data);
        refresh()
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "pallet_id":pallet_id,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="PalletID" value={pallet_id} caption="PalletID"/>
            <br/>
            <Button label="Empty" className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />

        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup} enabled={lineCount === 0 ? true : false }/>
        </>
    );
}
