import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                    } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                     } from '../../components/jmt_api_button';
import { JMT_FIELD_TEXTBOX                  } from '../../components/jmt_field_textbox';

import { POPUP_PALLET_STOCK_LOOKUP          } from './pallet_stock_lookup';


export const POPUP_PALLET_LINE_ADD = ({pallet_id, refresh}) => {
    const endpoint = "/pallet/set/line/add";
    const title = "Pallet Line Add"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_data, set_axios_data] = useState({
        "data": [], 
    });

    const [StockID, setStockID] = useState("");
    const [StockCode, setStockCode] = useState("");
    const [Description, setDescription] = useState("");
    const [Qty, setQty] = useState("");
    
    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        setDisplayBasic(false);        
        refresh()
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "pallet_id":pallet_id,
                "stock_id":StockID,
                "qty":Qty
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="PalletID" value={pallet_id} caption="PalletID"/>
            <POPUP_PALLET_STOCK_LOOKUP caption="StockID" id="StockID" value={StockID} setter={setStockID} setterStockCode={setStockCode} setterDescription={setDescription}/>
            <JMT_FIELD_LABEL id="StockCode" value={StockCode} caption="StockCode"/>
            <JMT_FIELD_LABEL id="Description" value={Description} caption="Description"/>
            <JMT_FIELD_TEXTBOX id="Qty" value={Qty} setter={setQty} caption="Qty" />
            <br/>
            <Button label="Add" className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />

        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
