import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX } from '../../components/jmt_field_textbox.jsx';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_USER_RESET_PASSWORD = ({username}) => {
    const endpoint = "/user/set/reset/password";
    const title = "Reset Password"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");
    const [Password, setPassword] = useState("");


    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setDisplayBasic(false);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "username":username, 
                    "password":Password, 
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            position={"top"} 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_TEXTBOX id="Password" value={Password} setter={setPassword} caption="Password" unit=""/>
            <br/>
            <Button label={title} onClick={handleClick} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" />
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

