import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTAREA             } from '../../components/jmt_field_textarea.jsx';

export const POPUP_PICKING_NOT_URGENT_ORDER = ({invoice_id, refresh}) => {
    const endpoint = "/warehouse/picking/set/not/urgent/order";
    const title = "Not Urgent"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [Note, setNote] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setNote("");
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "invoice_id":invoice_id,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            style={{ width:'80vw'}}
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="InvoiceID" value={invoice_id} caption="InvoiceID"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
