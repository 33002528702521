import React from 'react';

import { Panel } from 'primereact/panel';

export const AppSettings = () => {

    return (
        <div className="grid">
            <div className="col-12 lg:col-4">
                <Panel header="Settings" toggleable>
                </Panel>
            </div>        
        </div>
    );
}
