import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTBOX                } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN               } from '../../components/jmt_field_dropdown'

export const POPUP_QUEUE_EDIT_DETAILS = ({ queue_id, data, refresh }) => {
    const localSName = "LCBI_PreferredDay";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const endpoint = "/queue/set/edit/details";
    const title = "Edit Details"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [CustomerID, setCustomerID] = useState("")
    const [CustomerName, setCustomerName] = useState("")
    const [CustomerCode, setCustomerCode] = useState("")
    const [Balance, setBalance] = useState("")
    const [Terms, setTerms] = useState("")
    const [Address, setAddress] = useState("")
    const [VenueType, setVenueType] = useState("")
    const [Area, setArea] = useState("")
    const [Tel, setTel] = useState("")
    const [Tel2, setTel2] = useState("")
    const [Fax, setFax] = useState("")
    const [Fax2, setFax2] = useState("")
    const [Email, setEmail] = useState("")
    const [OnHold, setOnHold] = useState("")
    const [BusinessType, setBusinessType] = useState("")
    const [KeyAccount, setKeyAccount] = useState("")
    const [GroupHeadOffice, setGroupHeadOffice] = useState("")
    const [GroupAccount, setGroupAccount] = useState("")
    const [Contact_Person, setContact_Person] = useState("")
    const [Rep, setRep] = useState("")
    const [PreferredDay, setPreferredDay] = useState("")
    const [Note, setNote] = useState("");

    useEffect(() => {
        setCustomerID(data[0]?.CustomerID)
        setTel(data[0]?.Tel)
        setTel2(data[0]?.Tel2)
        setFax(data[0]?.Fax)
        setFax2(data[0]?.Fax2)
        setEmail(data[0]?.Email)
        setVenueType(data[0]?.VenueType)
        setRep(data[0]?.Rep)
        setGroupHeadOffice(data[0]?.GroupHeadOffice) 
        setGroupAccount(data[0]?.GroupAccount)
        setArea(data[0]?.Area)
        setContact_Person(data[0]?.Contact_Person)
        // setPreferredDay(data[0]?.PreferredDay)
        localStorage.setItem(localSName, PreferredDay);
    }, [data]);
    
    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "customer_id":CustomerID,
                "tel":Tel,
                "tel_2":Tel2,
                "fax":Fax,
                "fax_2":Fax2,
                "email":Email,
                "venue_type":VenueType,
                "rep":Rep,
                "group_head_office":GroupHeadOffice,
                "group_account":GroupAccount,
                "area":Area,
                "contact_person":Contact_Person,
                "preferred_day":PreferredDay.PreferredDay,
                "note":Note,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            {/* <div className="grid"> */}
                {/* <div className="col-12 lg:col-6"> */}
                    {/* <JMT_FIELD_LABEL id="CustomerID" value={CustomerID} setter={setCustomerID} caption="CustomerID"/>
                    <JMT_FIELD_LABEL id="CustomerName" value={CustomerName} setter={setCustomerName} caption="CustomerName"/>
                    <JMT_FIELD_LABEL id="CustomerCode" value={CustomerCode} setter={setCustomerCode} caption="CustomerCode"/>
                    <JMT_FIELD_LABEL id="Balance" value={Balance} setter={setBalance} caption="Balance"/>
                    <JMT_FIELD_LABEL id="Terms" value={Terms} setter={setTerms} caption="Terms"/>
                    <JMT_FIELD_LABEL id="Address" value={Address} setter={setAddress} caption="Address"/> */}
                    <JMT_FIELD_TEXTBOX id="VenueType" value={VenueType} setter={setVenueType} caption="VenueType"/>
                    <JMT_FIELD_TEXTBOX id="Area" value={Area} setter={setArea} caption="Area"/>
                    <JMT_FIELD_TEXTBOX id="Tel" value={Tel} setter={setTel} caption="Tel"/>
                    <JMT_FIELD_TEXTBOX id="Tel2" value={Tel2} setter={setTel2} caption="Tel2"/> 
                    <JMT_FIELD_TEXTBOX id="Fax" value={Fax} setter={setFax} caption="Fax"/>
                    <JMT_FIELD_TEXTBOX id="Fax2" value={Fax2} setter={setFax2} caption="Fax2"/>
                {/* </div> */}
                {/* <div className="col-12 lg:col-6"> */}
                    <JMT_FIELD_TEXTBOX id="Email" value={Email} setter={setEmail} caption="Email"/>
                    {/* <JMT_FIELD_LABEL id="OnHold" value={OnHold} setter={setOnHold} caption="OnHold"/>
                    <JMT_FIELD_LABEL id="BusinessType" value={BusinessType} setter={setBusinessType} caption="BusinessType"/>
                    <JMT_FIELD_LABEL id="KeyAccount" value={KeyAccount} setter={setKeyAccount} caption="KeyAccount"/> */}
                    <JMT_FIELD_TEXTBOX id="GroupHeadOffice" value={GroupHeadOffice} setter={setGroupHeadOffice} caption="GroupHeadOffice"/>
                    <JMT_FIELD_TEXTBOX id="GroupAccount" value={GroupAccount} setter={setGroupAccount} caption="GroupAccount"/>
                    <JMT_FIELD_TEXTBOX id="Contact_Person" value={Contact_Person} setter={setContact_Person} caption="Contact_Person"/>
                    <JMT_FIELD_TEXTBOX id="Rep" value={Rep} setter={setRep} caption="Rep"/>
                    <JMT_FIELD_DROPDOWN id="PreferredDay" list={[
                                        {PreferredDay: 'No Day'},
                                        {PreferredDay: 'Monday'},
                                        {PreferredDay: 'Tuesday'},
                                        {PreferredDay: 'Wednesday'},
                                        {PreferredDay: 'Thursday'},
                                        {PreferredDay: 'Friday'},
                                    ]} value={PreferredDay} setter={setPreferredDay} caption="PreferredDay" />
                {/* </div> */}
                <JMT_FIELD_TEXTBOX id="Note" value={Note} setter={setNote} caption="Note"/>
            {/* </div> */}
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />
            {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
