import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX } from '../../components/jmt_field_textbox';
import { JMT_FIELD_LABEL } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_USER_UPDATE = ({ username, data, refresh }) => {
    const endpoint = "/user/set/update";
    const title = "Update User"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");
    const [Name, setName] = useState("");
    const [Surname, setSurname] = useState("");
    const [JobTitle, setJobTitle] = useState("");
    const [Dept, setDept] = useState("");
    const [Cell, setCell] = useState("");
    const [Email, setEmail] = useState("");
    const [TelegramID, setTelegramID] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
        setName(data?.Name);
        setSurname(data?.Surname);
        setJobTitle(data?.JobTitle);
        setDept(data?.Dept);
        setCell(data?.Cell);
        setEmail(data?.Email);
        setTelegramID(data?.TelegramID);
    }
    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setDisplayBasic(false);
        refresh()
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "username":username, 
                    "name":Name,
                    "surname":Surname,
                    "job_title":JobTitle,
                    "dept":Dept,
                    "cell":Cell,
                    "email":Email,
                    "telegram_id":TelegramID,
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
            />        
            <Dialog 
                header={title} 
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <JMT_FIELD_LABEL id="UserName" value={username} caption="UserName" />
                <JMT_FIELD_TEXTBOX id="Name" value={Name} setter={setName} caption="Name" />
                <JMT_FIELD_TEXTBOX id="Surname" value={Surname} setter={setSurname} caption="Surname" />
                <JMT_FIELD_TEXTBOX id="JobTitle" value={JobTitle} setter={setJobTitle} caption="JobTitle" />
                <JMT_FIELD_TEXTBOX id="Dept" value={Dept} setter={setDept} caption="Dept" />
                <JMT_FIELD_TEXTBOX id="Cell" value={Cell} setter={setCell} caption="Cell" />
                <JMT_FIELD_TEXTBOX id="Email" value={Email} setter={setEmail} caption="Email" />
                <JMT_FIELD_TEXTBOX id="TelegramID" value={TelegramID} setter={setTelegramID} caption="TelegramID" />
                <br/>
                <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleClick} />
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

