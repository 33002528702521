import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_LEAD_PROCCESSED = ({ lead_id, data, refresh}) => {
    const endpoint = "/lead/set/processed";
    const title = "Process Lead"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "lead_id": lead_id
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="CustomerName" value={data?.CustomerName} caption="CustomerName"/>
            <JMT_FIELD_LABEL id="CustomerCode" value={data?.CustomerCode} caption="CustomerCode"/>
            <JMT_FIELD_LABEL id="Balance" value={data?.Balance} caption="Balance"/>
            <JMT_FIELD_LABEL id="Terms" value={data?.Terms} caption="Terms"/>
            <JMT_FIELD_LABEL id="Address" value={data?.Address} caption="Address"/>
            <JMT_FIELD_LABEL id="VenueType" value={data?.VenueType} caption="VenueType"/>
            <JMT_FIELD_LABEL id="Area" value={data?.Area} caption="Area"/>
            <JMT_FIELD_LABEL id="Tel" value={data?.Tel} caption="Tel"/>
            <JMT_FIELD_LABEL id="Tel2" value={data?.Tel2} caption="Tel2"/>
            <JMT_FIELD_LABEL id="Fax" value={data?.Fax} caption="Fax"/>
            <JMT_FIELD_LABEL id="Fax2" value={data?.Fax2} caption="Fax2"/>
            <JMT_FIELD_LABEL id="Email" value={data?.Email} caption="Email"/>
            <JMT_FIELD_LABEL id="OnHold" value={data?.OnHold} caption="OnHold"/>
            <JMT_FIELD_LABEL id="BusinessType" value={data?.BusinessType} caption="BusinessType"/>
            <JMT_FIELD_LABEL id="KeyAccount" value={data?.KeyAccount} caption="KeyAccount"/>
            <JMT_FIELD_LABEL id="GroupHeadOffice" value={data?.GroupHeadOffice} caption="GroupHeadOffice"/>
            <JMT_FIELD_LABEL id="GroupAccount" value={data?.GroupAccount} caption="GroupAccount"/>
            <JMT_FIELD_LABEL id="Contact_Person" value={data?.Contact_Person} caption="Contact_Person"/>
            <JMT_FIELD_LABEL id="Rep" value={data?.Rep} caption="Rep"/>
            <JMT_FIELD_LABEL id="PreferredDay" value={data?.PreferredDay} caption="PreferredDay"/>
            <JMT_FIELD_LABEL id="Processed" value={data?.Processed} caption="Processed"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>  
        </>
    );
}
