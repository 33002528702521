import React from 'react';
import { InputText } from 'primereact/inputtext';

export const JMT_FIELD_TEXTBOX = ({ id, caption, value, setter, error=false }) => {
    
    return (
        <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                {
                    !error
                ?
                <>
                    <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                    <InputText 
                        style={{border:'0px', width:'100%', padding:'2px', backgroundColor:"lavender"}} 
                        id={id} 
                        value={value} 
                        placeholder="" 
                        autoComplete="off"
                        onChange={(e) => setter(e.target.value, id)}
                        />
                    </>
                :
                    <>
                    <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                    <InputText 
                        style={{border:'0px', width:'100%', padding:'2px', backgroundColor:"red"}} 
                        id={id} 
                        value={value} 
                        placeholder="" 
                        autoComplete="off"
                        onChange={(e) => setter(e.target.value, id)}
                        />
                    </>    
                }
            </div>
            {error}
        </div>
    );
}

