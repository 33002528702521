import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                  } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

import { POPUP_BI_PRODUCTS_LOW_QTY      } from './pastel_bi_products_low_qty'
import { POPUP_BI_SALES_COMPARITIVES    } from './pastel_bi_sales_comparitives'
import { POPUP_BI_SALES_PERIODS         } from './pastel_bi_sales_periods'
import { POPUP_BI_SALES_TO_DATE         } from './pastel_bi_sales_to_date'
import { POPUP_BI_CATEGORY_YEARLY       } from './pastel_bi_category_yearly'
import { POPUP_CUSTOMER_PROVINCE        } from './pastel_customer_province'
import { POPUP_DAILY_SALES_STATS        } from './pastel_daily_sales_stats'
import { POPUP_DAILY_SALES              } from './pastel_daily_sales'
import { POPUP_DASHBOARD_NEW_CUSTOMERS  } from './pastel_dashboard_new_customers'
import { POPUP_EXCEPTIONS_CUSTOMERS     } from './pastel_exceptions_customers'
import { POPUP_EXCEPTIONS_STOCK         } from './pastel_exceptions_stock'
import { POPUP_PROBLEMS_CUSTOMER        } from './pastel_problems_customer'
import { POPUP_STOCK_CATEGORY_LEVEL     } from './pastel_stock_category_header'
import { POPUP_TREND_CUSTOMER           } from './pastel_trend_customer'
import { POPUP_TREND_CUSTOMER_VENUE_TYPE} from './pastel_trend_customer_venue_type'
import { POPUP_TREND_STOCK              } from './pastel_trend_stock'
import { POPUP_TREND_STOCK_CATEGORY     } from './pastel_trend_stock_category'


export const Page_Pastel = () => {
    const localSName = "Afromix_Username";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [Username, setUSername] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "data":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, Username);
        handleRefresh()
    }, [Username]);

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/pastel/get/read' 
                payload={{
                    "username":Username
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="Pastel">
                    <POPUP_BI_PRODUCTS_LOW_QTY/>
                    <POPUP_BI_SALES_COMPARITIVES/>
                    <POPUP_BI_SALES_PERIODS/>
                    <POPUP_BI_SALES_TO_DATE/>
                    <POPUP_BI_CATEGORY_YEARLY/>
                    <POPUP_CUSTOMER_PROVINCE/>
                    <POPUP_DAILY_SALES_STATS/>
                    <POPUP_DAILY_SALES/>
                    <POPUP_DASHBOARD_NEW_CUSTOMERS/>
                    <POPUP_EXCEPTIONS_CUSTOMERS/>
                    <POPUP_EXCEPTIONS_STOCK/>
                    <POPUP_PROBLEMS_CUSTOMER/>
                    <POPUP_STOCK_CATEGORY_LEVEL/>
                    <POPUP_TREND_CUSTOMER/>
                    <POPUP_TREND_CUSTOMER_VENUE_TYPE/>
                    <POPUP_TREND_STOCK/>
                    <POPUP_TREND_STOCK_CATEGORY/>
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
