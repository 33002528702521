import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                          } from '../../components/axios'
import { JMT_XLSX_DIALOG                } from '../../components/jmt_xlsx_dialog';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

import { POPUP_AR_ADD_NOTE                 } from './ar_add_note';
import { POPUP_AR_LOOKUP              } from './lookup';

export const Page_Ar = () => {
    const localSName = "Afromix_ARCustomer";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [ARCustomer, setARCustomer] = useState(initID);
    const [Amount, setAmount] = useState(1000);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "customer":[],
        "notes":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, ARCustomer);
        handleRefresh()
    }, [ARCustomer]);

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/ar/get/read' 
                payload={{
                    "customer_id":ARCustomer
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-3">
                <Panel header="AR">
                    <POPUP_AR_LOOKUP id="CustomerID" value={ARCustomer} setter={setARCustomer} caption="CustomerID"/>
                    <JMT_FIELD_LABEL id="CustomerCode" value={axios_data.customer[0]?.CustomerCode} caption="CustomerCode" />
                    <JMT_FIELD_LABEL id="Name" value={axios_data.customer[0]?.Name} caption="Name" />
                    <JMT_FIELD_LABEL id="Tel" value={axios_data.customer[0]?.Tel} caption="Tel" />
                    <JMT_FIELD_LABEL id="Balance" value={axios_data.customer[0]?.Balance} caption="Balance" />
                    <JMT_FIELD_LABEL id="Terms" value={axios_data.customer[0]?.Terms} caption="Terms" />
                    <JMT_FIELD_LABEL id="Overdue" value={axios_data.customer[0]?.Overdue} caption="Overdue" />
                    <br/>
                    <JMT_PDF_DIALOG title="Notes" endpoint="/ar/pdf/notes" payload={{"customer_id": ARCustomer}}/>
                    <JMT_PDF_DIALOG title="Open Items" endpoint="/ar/pdf/open/items" payload={{"customer_id": ARCustomer}}/>
                    <JMT_PDF_DIALOG title="Statement Summary" endpoint="/ar/pdf/statement/summary" payload={{"customer_id": ARCustomer}}/>
                    <JMT_PDF_DIALOG title="Statement" endpoint="/ar/pdf/statement" payload={{"customer_id": ARCustomer}}/>
                    <JMT_PDF_DIALOG title="Statement History" endpoint="/ar/pdf/transaction/history" payload={{"customer_id": ARCustomer}}/>
                    <br/>
                    {/* <pre>{JSON.stringify(axios_data, null, 2) }</pre> */}
                    <POPUP_AR_ADD_NOTE customer_id={ARCustomer} />
                </Panel>
            </div>
            <div className="col-12 lg:col-3">
                <Panel header="Debt Collection">
                    <JMT_FIELD_LABEL id="Amount" value={Amount} caption="Amount" />
                    <br/>
                    <JMT_PDF_DIALOG title="Debt Collection" endpoint="/ar/pdf/debt/collection" payload={{"amount": Amount}}/>
                    <JMT_XLSX_DIALOG title="Cash Flow" endpoint="/ar/xlsx/cash/flow" payload={{}}/>
                    <JMT_XLSX_DIALOG title="Exceptions" endpoint="/ar/xlsx/exceptions" payload={{}}/>
                    <JMT_XLSX_DIALOG title="Overdue" endpoint="/ar/xlsx/overdue" payload={{"amount": Amount}}/>
                </Panel>
            </div>
            <div className="col-12 lg:col-6">
                <Panel header="Customer Notes">
                <DataTable 
                        style={{padding: '0px'}}  
                        dataKey="LogID" 
                        rowHover 
                        showGridlines
                        size="small"
                        value={axios_data.notes} 
                        responsiveLayout="scroll"
                        loading={axios_loading}
                    >
                        <Column field="UserName" header="UserName"></Column>
                        <Column field="Logtime" header="Logtime"></Column>
                        <Column field="NoteType" header="NoteType"></Column>
                        <Column field="Note" header="Note"></Column>
                        <Column header="NoteValue" bodyStyle={{ textAlign: 'right'}} body={(rowdata)=>{return rowdata.NoteValue.toLocaleString(undefined, {minimumFractionDigits: 2});}}></Column>
                        <Column field="NoteDate" header="NoteDate"></Column>
                    </DataTable>
                </Panel>
            </div>
        </div>
    );
}
