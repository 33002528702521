import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

export const JMT_FIELD_TEXTAREA = (props) => {

    return (
        <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                <span style={{width:'300px', padding:'2px'}} >{props.caption}</span>
                <InputTextarea 
                    style={{border:'0px', width:'100%', padding:'2px', backgroundColor:'lavender'}} 
                    id={props.id} 
                    value={props.value} 
                    placeholder="" 
                    autoComplete="off"
                    onChange={(e) => props.setter(e.target.value)} 
                    autoResize 
                    rows="3" 
                    cols="30" 
                />
            </div>
        </div>
    );
}

