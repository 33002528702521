import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel              } from 'primereact/tabview';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { JMT_FIELD_DATEBOX                  } from '../../components/jmt_field_datebox.jsx';
import { JMT_XLSX_DIALOG                    } from '../../components/jmt_xlsx_dialog';
import { JMT_FIELD_TEXTBOX                  } from '../../components/jmt_field_textbox.jsx';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                    } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                      } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                     } from '../../components/jmt_pdf_dialog';

import { POPUP_QUEUE_LOOKUP                 } from './lookup';  
import { POPUP_QUEUE_LOAD                   } from './queue_load';
import { POPUP_QUEUE_EDIT_DETAILS           } from './queue_edit_details.jsx';
import { POPUP_QUEUE_PLACE_ORDER_COLLECT    } from './queue_place_order_collect.jsx';
import { POPUP_QUEUE_PLACE_ORDER_DELIVER    } from './queue_place_order_deliver.jsx';
import { POPUP_QUEUE_PLACE_ORDER            } from './queue_place_order.jsx';
import { POPUP_QUEUE_SEND_REP               } from './queue_send_rep.jsx';
import { POPUP_QUEUE_SEND_TRAINER           } from './queue_send_trainer.jsx';
import { POPUP_QUEUE_WILL_ORDER             } from './queue_will_order.jsx';
import { POPUP_QUEUE_USING_DISTRIBUTOR      } from './queue_using_distributor.jsx';
import { POPUP_QUEUE_SUFFICIENT_STOCK       } from './queue_sufficient_stock.jsx';
import { POPUP_QUEUE_NO_ANSWER              } from './queue_no_answer.jsx';
import { POPUP_QUEUE_CALL_LATER             } from './queue_call_later.jsx';
import { POPUP_QUEUE_CLOSED_DOWN            } from './queue_closed_down.jsx';
import { POPUP_QUEUE_USING_OPPOSITION       } from './queue_using_opposition.jsx';
import { POPUP_QUEUE_NOT_INTERESTED         } from './queue_not_interested.jsx';

import { POPUP_QUEUE_PROCESS_CALL_LATER     } from './queue_process_call_later.jsx';
import { POPUP_QUEUE_PROCESS_CLOSED_DOWN    } from './queue_process_closed_down.jsx';
import { POPUP_QUEUE_PROCESS_NO_ANSWER_3_TIMES } from './queue_process_no_answer_3_times.jsx';


export const Page_Queue = () => {
    const localSName = "Afromix_QueueID";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [QueueID, setQueueID] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "queue_data":[],
        "queue_customer_data": [],
        "never_data":[],
        "no_rep_data":[],
        "spend_0_to_6_months_data":[],
        "spend_0_to_12_months_data":[],
        "customer_last_5_orders":[],
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, QueueID);
        handleRefresh()
    }, [QueueID]);

    const [activeIndexReports, setactiveIndexReports] = useState(0);
    const [activeIndexOutgoing, setactiveIndexOutgoing] = useState(0);

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/queue/get/read' 
                payload={{
                    "queue_id":QueueID
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-8">
                <Panel header="Queue">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <POPUP_QUEUE_LOOKUP id="QueueID" value={QueueID} setter={setQueueID} caption="QueueID"/>
                            <JMT_FIELD_LABEL id="CustomerID" value={axios_data.queue_customer_data[0]?.CustomerID} caption="CustomerID"/>
                            <JMT_FIELD_LABEL id="CustomerName" value={axios_data.queue_customer_data[0]?.CustomerName} caption="CustomerName"/>
                            <JMT_FIELD_LABEL id="CustomerCode" value={axios_data.queue_customer_data[0]?.CustomerCode} caption="CustomerCode"/>
                            <JMT_FIELD_LABEL id="Balance" value={axios_data.queue_customer_data[0]?.Balance} caption="Balance"/>
                            <JMT_FIELD_LABEL id="Terms" value={axios_data.queue_customer_data[0]?.Terms} caption="Terms"/>
                            <JMT_FIELD_LABEL id="Address" value={axios_data.queue_customer_data[0]?.Address} caption="Address"/>
                            <JMT_FIELD_LABEL id="Note" value={axios_data.queue_customer_data[0]?.Note} caption="Note"/>
                            <JMT_FIELD_LABEL id="Area" value={axios_data.queue_customer_data[0]?.Area} caption="Area"/>
                            <JMT_FIELD_LABEL id="Tel" value={axios_data.queue_customer_data[0]?.Tel} caption="Tel"/>
                            <JMT_FIELD_LABEL id="Tel2" value={axios_data.queue_customer_data[0]?.Tel2} caption="Tel2"/> 
                            <JMT_FIELD_LABEL id="Fax" value={axios_data.queue_customer_data[0]?.Fax} caption="Fax"/>
                            <JMT_FIELD_LABEL id="Fax2" value={axios_data.queue_customer_data[0]?.Fax2} caption="Fax2"/>
                        </div>
                        <div className="col-12 lg:col-6">
                            <JMT_FIELD_LABEL id="Email" value={axios_data.queue_customer_data[0]?.Email} caption="Email"/>
                            <JMT_FIELD_LABEL id="OnHold" value={axios_data.queue_customer_data[0]?.OnHold} caption="OnHold"/>
                            <JMT_FIELD_LABEL id="BusinessType" value={axios_data.queue_customer_data[0]?.BusinessType} caption="BusinessType"/>
                            <JMT_FIELD_LABEL id="KeyAccount" value={axios_data.queue_customer_data[0]?.KeyAccount} caption="KeyAccount"/>
                            <JMT_FIELD_LABEL id="GroupHeadOffice" value={axios_data.queue_customer_data[0]?.GroupHeadOffice} caption="GroupHeadOffice"/>
                            <JMT_FIELD_LABEL id="GroupAccount" value={axios_data.queue_customer_data[0]?.GroupAccount} caption="GroupAccount"/>
                            <JMT_FIELD_LABEL id="Contact_Person" value={axios_data.queue_customer_data[0]?.Contact_Person} caption="Contact_Person"/>
                            <JMT_FIELD_LABEL id="Rep" value={axios_data.queue_customer_data[0]?.Rep} caption="Rep"/>
                            <JMT_FIELD_LABEL id="PreferredDay" value={axios_data.queue_customer_data[0]?.PreferredDay} caption="PreferredDay"/>
                            <JMT_FIELD_LABEL id="Processed" value={axios_data.queue_customer_data[0]?.Processed} caption="Processed"/>
                        </div>
                    </div>
                    <br/>
                    <POPUP_QUEUE_LOAD/>
                    <POPUP_QUEUE_EDIT_DETAILS queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <br/>
                </Panel>
                <br/>
                <Panel header="Actions">
                    <POPUP_QUEUE_PLACE_ORDER queue_id={QueueID} data={axios_data.queue_customer_data} customer_last_5_orders={axios_data.customer_last_5_orders} refresh={handleRefresh}/>
                    <POPUP_QUEUE_PLACE_ORDER_COLLECT queue_id={QueueID} data={axios_data.queue_customer_data} customer_last_5_orders={axios_data.customer_last_5_orders} refresh={handleRefresh}/>
                    <POPUP_QUEUE_PLACE_ORDER_DELIVER queue_id={QueueID} data={axios_data.queue_customer_data} customer_last_5_orders={axios_data.customer_last_5_orders} refresh={handleRefresh}/>
                    <POPUP_QUEUE_SEND_REP queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_SEND_TRAINER queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_WILL_ORDER queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_USING_DISTRIBUTOR queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_SUFFICIENT_STOCK queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>   
                    <POPUP_QUEUE_NO_ANSWER queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_CALL_LATER queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_CLOSED_DOWN queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_USING_OPPOSITION queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                    <POPUP_QUEUE_NOT_INTERESTED queue_id={QueueID} data={axios_data.queue_customer_data} refresh={handleRefresh}/>
                </Panel>
                <br/>
                <Panel header="Process">
                    <div className="grid">
                        <POPUP_QUEUE_PROCESS_CALL_LATER queue_id={QueueID} refresh={handleRefresh}/>
                        <POPUP_QUEUE_PROCESS_CLOSED_DOWN queue_id={QueueID} refresh={handleRefresh}/>
                        <POPUP_QUEUE_PROCESS_NO_ANSWER_3_TIMES queue_id={QueueID} refresh={handleRefresh}/>
                    </div>
                </Panel>
                <br/>
                <Panel header="Queue Spend Reports">
                <TabView activeIndex={activeIndexReports} onTabChange={(e) => setactiveIndexReports(e.index)}>
                    <TabPanel header="Never">
                        <DataTable 
                            style={{padding: '0px'}}  
                            value={axios_data.never_data} 
                            loading={axios_loading} 
                            dataKey="Decription" 
                            rowHover 
                            showGridlines
                            size="small"
                            responsiveLayout="scroll"
                        >
                            <Column header="CustomerID" field="CustomerID"/>
                            <Column header="Name" field="Name"/>
                            <Column header="BusinessType" field="BusinessType"/>
                            <Column header="Closed" field="Closed"/>
                            <Column header="Area" field="Area"/>
                            <Column header="Note" field="Note"/>
                            <Column header="Contact_Person" field="Contact_Person"/>
                            <Column header="Spend0To6" field="Spend0To6"/>
                            <Column header="Spend7To12" field="Spend7To12"/>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="No Rep">
                        <DataTable 
                            style={{padding: '0px'}}  
                            value={axios_data.no_rep_data} 
                            loading={axios_loading} 
                            dataKey="CustomerID" 
                            rowHover 
                            showGridlines
                            size="small"
                            responsiveLayout="scroll"
                        >
                            <Column header="CustomerID" field="CustomerID"/>
                            <Column header="Name" field="Name"/>
                            <Column header="BusinessType" field="BusinessType"/>
                            <Column header="Closed" field="Closed"/>
                            <Column header="Area" field="Area"/>
                            <Column header="Note" field="Note"/>
                            <Column header="Contact_Person" field="Contact_Person"/>
                            <Column header="Spend0To6" field="Spend0To6"/>
                            <Column header="Spend7To12" field="Spend7To12"/>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Spend 0 to 6">
                        <DataTable 
                            style={{padding: '0px'}}  
                            value={axios_data.spend_0_to_6_months_data} 
                            loading={axios_loading} 
                            dataKey="CustomerID" 
                            rowHover 
                            showGridlines
                            size="small"
                            responsiveLayout="scroll"
                        >
                            <Column header="CustomerID" field="CustomerID"/>
                            <Column header="Name" field="Name"/>
                            <Column header="BusinessType" field="BusinessType"/>
                            <Column header="Closed" field="Closed"/>
                            <Column header="Area" field="Area"/>
                            <Column header="Note" field="Note"/>
                            <Column header="Contact_Person" field="Contact_Person"/>
                            <Column header="Spend0To6" field="Spend0To6"/>
                            <Column header="Spend7To12" field="Spend7To12"/>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Spend 0 to 12">
                        <DataTable 
                            style={{padding: '0px'}}  
                            value={axios_data.spend_0_to_12_months_data} 
                            loading={axios_loading} 
                            dataKey="CustomerID" 
                            rowHover 
                            showGridlines
                            size="small"
                            responsiveLayout="scroll"
                        >
                            <Column header="CustomerID" field="CustomerID"/>
                            <Column header="Name" field="Name"/>
                            <Column header="BusinessType" field="BusinessType"/>
                            <Column header="Closed" field="Closed"/>
                            <Column header="Area" field="Area"/>
                            <Column header="Note" field="Note"/>
                            <Column header="Contact_Person" field="Contact_Person"/>
                            <Column header="Spend0To6" field="Spend0To6"/>
                            <Column header="Spend7To12" field="Spend7To12"/>
                            <Column header="Spend13To18" field="Spend13To18"/>
                        </DataTable>
                    </TabPanel>
                </TabView>
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
