import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

import { POPUP_ROLE_LOOKUP              } from './lookup';
import { POPUP_ROLE_DELETE              } from './role_delete';
import { POPUP_ROLE_CREATE              } from './role_create';


import { POPUP_ROLE_UPDATE              } from './role_update';
import { POPUP_ROLE_ENDPOINT_UPDATE     } from './role_endpoint_update';


export const Page_Role = () => {
    const localSName = "RBLF_RoleName";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [RoleName, setRoleName] = useState(initID);
    const [Description, setDescription] = useState("");

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "role":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, RoleName);
        handleRefresh()
    }, [RoleName]);

    const [date,            setDate             ] = useState("");

    var yesterday = new Date();
    yesterday.setDate(new Date().getDate()-1);
    yesterday = yesterday.toISOString().split('T')[0];
    var today =  new Date().toISOString().split('T')[0];

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/role/get/read' 
                payload={{
                    "role_name":RoleName
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="Role">
                    <POPUP_ROLE_LOOKUP id="Rolename" value={RoleName} setter={setRoleName} caption="RoleName"/>
                    <JMT_FIELD_LABEL id="Description" value={axios_data.role[0]?.Description} caption="Description" />
                    <br/>
                    <POPUP_ROLE_CREATE setter={setRoleName}/>
                    <POPUP_ROLE_UPDATE RoleName={RoleName} data={axios_data.role[0]} refresh={handleRefresh}/>
                    <POPUP_ROLE_DELETE RoleName={RoleName} data={axios_data.role[0]} setter={setRoleName}/>
                    <POPUP_ROLE_ENDPOINT_UPDATE RoleName={RoleName} UserData={axios_data}/>
                    <JMT_PDF_DIALOG title="Datasheet" endpoint="/role/pdf/user/access" payload={{"role_name": RoleName}}/>
                </Panel>
                <br/>
            </div>
            <div className="col-12 lg:col-4">
            </div>
            <div className="col-12 lg:col-4">
            </div>
            {axios_loading}
        </div>
    );
}
