import React, { useState, useContext }  from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import AuthProvider from './context/AuthProvider';
import { AXIOS                              } from './components/axios'
import { topBarTitle, topBarTitleColor } from './_config/config';

export const AppTopbar = (props) => {

    const { authTokenObj, authUserObj, authAPISObj, authScreensObj } = useContext(AuthProvider)
    const [ authToken, setAuthToken ] = authTokenObj;
    const [ authUser, setAuthUser ] = authUserObj;
    const [ authAPIS, setAuthAPIS ] = authAPISObj;
    const [ authScreens, setAuthScreens ] = authScreensObj;

    const [axios_trigger, set_axios_trigger] = useState("");

    const confirm = (event) => {
        setAuthToken("");
        setAuthUser("");
        setAuthAPIS("");
        setAuthScreens("");
        localStorage.setItem("afromixCrmAuthUser", "");
        localStorage.setItem("afromixCrmAuthAPIS", "");
        localStorage.setItem("afromixCrmAuthScreens", "");
        localStorage.setItem("afromixCrmAuthToken", "");
    };

    const refreshAPIs = () => {
        set_axios_trigger(new Date());
    };

    const axios_success = (res) => {
        console.log(res.data);
        setAuthAPIS(res.data.apis); 
        setAuthScreens(res.data.screens); 
        localStorage.setItem("afromixCrmAuthAPIS", res.data.apis);
        localStorage.setItem("afromixCrmAuthScreens", res.data.screens);
        console.log(authToken)
        console.log(authUser)
        console.log(authAPIS)
        console.log(authScreens)
    }

    return (
        <div className="layout-topbar">
            <AXIOS 
                endpoint='/refresh' 
                payload={{
                    "username":authUser,
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <button 
                type="button" 
                className="p-link layout-menu-button layout-topbar-button" 
                onClick={props.onToggleMenuClick}
            >
                <i className="pi pi-bars"/>
            </button>
            <Link to="/dashboard" className="layout-topbar-logo">            
                <span style={{color:{topBarTitleColor}}}>{topBarTitle}</span>
            </Link>
            <button 
                type="button" 
                className="p-link layout-topbar-menu-button layout-topbar-button" 
                onClick={props.onMobileTopbarMenuClick}
            >
                <i className="pi pi-ellipsis-v" />
            </button>
            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <Link to="/" onClick={refreshAPIs} className="layout-topbar-button">            
                        <i className="pi pi-refresh"/>
                        <span>Refresh Permissions</span>
                    </Link>
                </li>
                <li>
                    <Link to="/settings" className="layout-topbar-button">            
                        <i className="pi pi-cog"/>
                        <span>Settings</span>
                    </Link>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={confirm}>
                        <i className="pi pi-user"/>
                        <span>Logout</span>
                    </button>
                </li>
            </ul>            
        </div>
    );
}






