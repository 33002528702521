import React from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const POPUP_WAREHOUSE_PANEL_PASTEL_NOTES = ({pastel_notes, loading}) => {

    return (
        <>
            <h4>Pastel Sales Order Notes</h4>
            <DataTable 
                style={{padding: '0px'}}  
                value={pastel_notes} 
                loading={loading} 
                dataKey="StockID" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
                >
                <Column header="LineNote" field="LineNote"/>
            </DataTable>
        </>
    );
}



