import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTBOX                } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN               } from '../../components/jmt_field_dropdown'

export const POPUP_LOCATION_UPDATE = ({ location_id, data, refresh }) => {
    const endpoint = "/location/set/update";
    const title = "Update"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [LocationType, setLocationType] = useState("");
    const [PalletRow, setPalletRow] = useState("");
    const [PalletLevel, setPalletLevel] = useState("");
    const [PalletSize, setPalletSize] = useState("");
    const [PalletColumn, setPalletColumn] = useState("");
    const [Active, setActive] = useState("");

    useEffect(() => {
        setLocationType({"LocationType":data[0]?.LocationType});
        setPalletRow(data[0]?.PalletRow);
        setPalletLevel(data[0]?.PalletLevel);
        setPalletSize(data[0]?.PalletSize);
        setPalletColumn(data[0]?.PalletColumn);
        setActive({"Active":data[0]?.Active});
    }, [data]);
    
    const handlePopup = () => {
        setDisplayBasic(true);
        setActive({"Active":data[0]?.Active});
    }

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "location_id":location_id,
                "location_type":LocationType.LocationType,
                "pallet_row":PalletRow,
                "pallet_level":PalletLevel,
                "pallet_size":PalletSize,
                "pallet_column":PalletColumn, 
                "active":Active.Active
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="LocationID" value={location_id} caption="LocationID"/>
            <JMT_FIELD_DROPDOWN id="LocationType" list={[
                                        {LocationType: 'Bluk'},
                                        {LocationType: 'Floor'},
                                        {LocationType: 'Picking'},
                                        {LocationType: 'Samples'},
                                    ]} value={LocationType} setter={setLocationType} caption="LocationType" />
            <JMT_FIELD_TEXTBOX id="PalletRow" value={PalletRow} setter={setPalletRow} caption="PalletRow"/>
            <JMT_FIELD_TEXTBOX id="PalletLevel" value={PalletLevel} setter={setPalletLevel} caption="PalletLevel"/>
            <JMT_FIELD_TEXTBOX id="PalletColumn" value={PalletColumn} setter={setPalletColumn} caption="PalletColumn"/>
            <JMT_FIELD_TEXTBOX id="PalletSize" value={PalletSize} setter={setPalletSize} caption="PalletSize"/>
            <JMT_FIELD_DROPDOWN id="Active" list={[
                                        {Active: 'Yes'},
                                        {Active: 'No'},
                                    ]} value={Active} setter={setActive} caption="Active" />
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />
            {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
