import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                    } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                      } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                     } from '../../components/jmt_pdf_dialog';
import { JMT_XLSX_DIALOG                    } from '../../components/jmt_xlsx_dialog';

import { POPUP_WAREHOUSE_DOUBLE_CHECKING    } from './warehouse_double_checking';
import { POPUP_PICKING_URGENT_ORDER         } from './warehouse_picking_urgent_order';
import { POPUP_PICKING_NOT_URGENT_ORDER     } from './warehouse_picking_not_urgent_order';
import { POPUP_WAREHOUSE_BACK_ORDERS        } from './warehouse_back_orders';
import { POPUP_WAREHOUSE_BARCODE_DCC        } from './warehouse_barcodes_dcc';
import { POPUP_WAREHOUSE_BARCODE_DRIVER     } from './warehouse_barcodes_driver';
import { POPUP_WAREHOUSE_ORDER_PROGRESS     } from './warehouse_order_progress';
import { POPUP_WAREHOUSE_CUSTOMER_LOOKUP    } from './warehouse_customer_lookup';
import { POPUP_WAREHOUSE_PICKING            } from './warehouse_picking';
import { POPUP_WAREHOUSE_TRIP_SHEET_REPORTS } from './warehouse_tripsheet_reports';
import { POPUP_WAREHOUSE_WEIGHTS            } from './warehouse_weights';


export const Page_Warehouse = () => {
    const localSName = "Afromix_Username";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [Username, setUSername] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        picking_items: 0, 
        weight_items: 0, 
        double_checking_items: 0,
    });

    const [CustomerID, setCustomerID] = useState("");
    const [InvoiceID, setInvoiceID] = useState("");
    const [Name, setName] = useState("");
    const [Area, setArea] = useState("");
    const [Qty, setQty] = useState("");
    const [BusinessType, setBusinessType] = useState("");

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, Username);
        handleRefresh()
    }, [Username]);


    return (
        <div className="grid">
            <AXIOS 
                endpoint='/warehouse/get/read' 
                payload={{
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="Warehouse - Overview">
                    <POPUP_WAREHOUSE_PICKING items={axios_data?.picking_items}/>
                    <POPUP_WAREHOUSE_WEIGHTS items={axios_data?.weight_items}/>
                    <POPUP_WAREHOUSE_DOUBLE_CHECKING items={axios_data?.double_checking_items}/>
                    <br/>
                    <POPUP_WAREHOUSE_BACK_ORDERS />

                </Panel>
                <Panel header="Warehouse - Customers">
                    <POPUP_WAREHOUSE_CUSTOMER_LOOKUP id="CustomerID" value={CustomerID} setter={setCustomerID} setterName={setName} setterArea={setArea} setterBusinessType={setBusinessType} caption="CustomerID"/>
                    <JMT_FIELD_LABEL id="Name" value={Name} caption="Name"/>
                    <JMT_FIELD_LABEL id="Area" value={Area} caption="Area"/>
                    <JMT_FIELD_LABEL id="BusinessType" value={BusinessType} caption="BusinessType"/>
                    <br/>
                    <POPUP_WAREHOUSE_ORDER_PROGRESS customer_id={CustomerID}/>
                </Panel>
                <br/>
                <Panel header="Warehouse - Picking">
                    
                    <br/>
                    <POPUP_PICKING_URGENT_ORDER invoice_id = {InvoiceID}/>
                    <POPUP_PICKING_NOT_URGENT_ORDER invoice_id = {InvoiceID}/>
                    <br/>
                    <JMT_XLSX_DIALOG title="Active" endpoint="/warehouse/picking/xlsx/active" payload={{}}/>
                </Panel>
                <br/>
                <Panel header="Warehouse - Reports">
                    <JMT_XLSX_DIALOG title="Delivery Notes" endpoint="/warehouse/xlsx/delivery/notes" payload={{}}/>
                </Panel>
            </div>
            <div className="col-12 lg:col-4">
                    <Panel header="Warehouse - Trip Sheet">
                        <POPUP_WAREHOUSE_TRIP_SHEET_REPORTS />
                    </Panel>
            </div>
            <div className="col-12 lg:col-4">
                    <Panel header="Warehouse - Barcodes">
                        <POPUP_WAREHOUSE_BARCODE_DCC/>
                        <POPUP_WAREHOUSE_BARCODE_DRIVER/>
                    </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
