import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                  } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

import { POPUP_HOTORNOT_LOOKUP          } from './lookup';
import { POPUP_PLACE_UPDATE              } from './hotornot_update_place';


export const Page_HorOrNot = () => {
    const localSName = "Afromix_Username";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [PlaceID, setPlaceID] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "place":[],
        "reps":[],
        "processed_per_day":[],
        "processed_by_user":[],
        "processed_by_rep":[],
        "sats_ds":[],
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, PlaceID);
        handleRefresh()
    }, [PlaceID]);

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }
    const { basicOptions, horizontalOptions, multiAxisOptions, stackedOptions } = getLightTheme();

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/hotornot/get/read' 
                payload={{
                    "place_id":PlaceID
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="HotOrNot">
                    <POPUP_HOTORNOT_LOOKUP id="PlaceID" value={PlaceID} setter={setPlaceID} caption="PlaceID"/>
                    <JMT_FIELD_LABEL id="Name" value={axios_data.place[0]?.Name} caption="Name" />
                    <JMT_FIELD_LABEL id="LocationLat" value={axios_data.place[0]?.LocationLat} caption="LocationLat" />
                    <JMT_FIELD_LABEL id="LocationLng" value={axios_data.place[0]?.LocationLng} caption="LocationLng" />
                    <JMT_FIELD_LABEL id="FormattedAddress" value={axios_data.place[0]?.FormattedAddress} caption="FormattedAddress" />
                    <JMT_FIELD_LABEL id="FormattedTel" value={axios_data.place[0]?.FormattedTel} caption="FormattedTel" />
                    <JMT_FIELD_LABEL id="Rating" value={axios_data.place[0]?.Rating} caption="Rating" />
                    <JMT_FIELD_LABEL id="Hours" value={axios_data.place[0]?.Hours} caption="Hours" />
                    <JMT_FIELD_LABEL id="Status" value={axios_data.place[0]?.Status} caption="Status" />
                    <JMT_FIELD_LABEL id="HotNot" value={axios_data.place[0]?.HotNot} caption="HotNot" />
                    <br/>
                    <POPUP_PLACE_UPDATE place_id={PlaceID} data={axios_data.place[0]} repdata={axios_data.reps} refresh={handleRefresh}/>
                    {/* <pre>{JSON.stringify(axios_data, null, 2) }</pre> */}
                </Panel>
                <br/>
                <Panel header="Location Details">
                    <a target="_blank" href={"https://www.google.com/maps/place/?q=place_id:" + PlaceID}>View in google maps</a>
                </Panel>
                <br/>
                <Panel header="Status Stats">
                    <DataTable 
                        style={{padding: '0px'}}  
                        value={axios_data.sats_ds} 
                        loading={axios_loading} 
                        dataKey="Decription" 
                        rowHover 
                        showGridlines
                        size="small"
                        responsiveLayout="scroll"
                    >
                        <Column header="Processed" field="Processed" />
                        <Column header="Description" field="Description" />
                        <Column header="Count" field="Count" />
                    </DataTable>
                </Panel>
            </div>
            <div className="col-12 lg:col-6">
                <Panel header="Stats">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <h3>Processed per Day</h3>
                            <Chart type="bar" data={axios_data.processed_per_day} options={stackedOptions} />
                            <h3>Processed by User</h3>
                            <Chart type="bar" data={axios_data.processed_by_user} options={stackedOptions} />
                        </div>
                        <div className="col-12 lg:col-6">
                            <h3>Processed by Rep</h3>
                            <Chart type="bar" data={axios_data.processed_by_rep} options={stackedOptions} />
                        </div>
                    </div>
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
