import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import AuthProvider from '../context/AuthProvider';
import { baseUrl } from '../_config/config';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

export const AXIOS = ({ endpoint, payload, trigger, loading=()=>{}, handleSuccess }) => {
    const [error, setError] = useState({
    });
    const [displayBasic, setDisplayBasic] = useState(false);
    const { authTokenObj, authUserObj, authAPISObj, authScreensObj } = useContext(AuthProvider)
    const [ authToken, setAuthToken ] = authTokenObj;
    const [ authUser, setAuthUser ] = authUserObj;
    const [ authAPIS, setAuthAPIS ] = authAPISObj;
    const [ authScreens, setAuthScreens ] = authScreensObj;
    const toast = useRef();

    const config = {
        validateStatus: function (status) {
            return true;
        }, 
        headers: {
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${authToken}`, 
            'User': authUser
        }
    }
    payload.current_user = authUser;
    var payloadStr = JSON.stringify(payload);

    useEffect(() => {
        if(trigger!==""){
            if(authAPIS===null && authScreens===null){
                console.log("NEVER HAPPEN");
            }
            // console.log(trigger);
            // console.log(endpoint);
            // console.log(payload);
            axios.post(baseUrl+endpoint, payloadStr, config)
            .then((res)=>{
                loading(false);
                // console.log(res);
                if(res.status === 200){
                    handleSuccess(res);
                } else {
                    switch(res.status){
                        case 401:
                            setAuthToken(""); 
                            localStorage.setItem("afromixCrmAuthToken","");        
                            setAuthUser(""); 
                            localStorage.setItem("afromixCrmAuthUser","");        
                            setAuthAPIS([]); 
                            localStorage.setItem("afromixCrmAuthAPIS","");        
                            setAuthScreens([]); 
                            localStorage.setItem("afromixCrmAuthScreens","");        
                            break;
                        case 400:
                            setError(res);    
                            toast.current.show({ severity: 'error', summary: res.data.detail, detail: endpoint, life: 6000 });
                            break;
                        case 403:
                            setError(res);    
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: endpoint, life: 6000 });
                            break;
                        case 422:
                            setError(res);    
                            setDisplayBasic(true)
                            break;
                        case 500:
                            setError(res);    
                            toast.current.show({ severity: 'error', summary: 'Error', detail: endpoint, life: 6000 });
                            break;
                        default :
                            setError(res);    
                            toast.current.show({ severity: 'error', summary: 'Error', detail: endpoint, life: 6000 });
                            // setDisplayBasic(true)
                            break;
                    }
                }    
            })
            .catch((err)=>{
                // console.log(err);
                setError(err);
                toast.current.show({ severity: 'error', summary: 'Error', detail: endpoint, life: 6000 });
                // setDisplayBasic(true)    
            });
        }
    }, [trigger]);

    return (
        <>
            <Toast ref={toast} />
            <Dialog 
                header={"Error: "+error?.status+" - "+error?.statusText} 
                style={{ width: '50vw' }} 
                visible={displayBasic} 
                onHide={() => setDisplayBasic(false)}
            >
                <br/>
                <h4>Errors</h4>
                {
                    error?.status === 422
                    ?
                    <>
                    <DataTable 
                    style={{padding: '0px'}}  
                    value={error?.data?.detail} 
                    dataKey="LineID" 
                    showGridlines
                    size="small"
                    responsiveLayout="scroll"
                    >
                        <Column header="loc" field="loc" />
                        <Column header="msg" field="msg" />
                        <Column header="type" field="type" />                        
                    </DataTable>                
                    <h4>Url</h4>
                    <p>{error?.config?.url}</p>
                    <h4>Payload</h4>
                    <p>{error?.config?.data}</p>
                    <hr/>
                    </>
                    :
                    <></>
                    // <pre>{JSON.stringify(error, null, "\t")}</pre>
                }
                {/* <pre>{JSON.stringify(error, null, "\t")}</pre> */}
            </Dialog>
        </>
    );
}

