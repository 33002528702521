import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox.jsx';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_USER_CREATE = ({ setter }) => {
    const endpoint = "/user/set/create";
    const title = "User Create"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");
    const [UserName, setUserName] = useState("");
    const [Name, setName] = useState("");
    const [Surname, setSurname] = useState("");
    const [JobTitle, setJobTitle] = useState("");
    const [Dept, setDept] = useState("");
    const [Cell, setCell] = useState("");
    const [Email, setEmail] = useState("");
    const [TelegramID, setTelegramID] = useState("");
    const [Password, setPassword] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setter(UserName);
        setUserName("");
        setDisplayBasic(false);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "username":UserName, 
                    "name":Name, 
                    "surname":Surname, 
                    "job_title": JobTitle,
                    "dept": Dept,
                    "cell": Cell,
                    "email": Email,
                    "telegram_id": TelegramID,
                    "password":Password,
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
            />
            <Dialog 
                header={title}
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <JMT_FIELD_TEXTBOX id="UserName" value={UserName} setter={setUserName} caption="UserName" unit=""/>
                <JMT_FIELD_TEXTBOX id="Name" value={Name} setter={setName} caption="Name" unit=""/>
                <JMT_FIELD_TEXTBOX id="Surname" value={Surname} setter={setSurname} caption="Surname" unit=""/>
                <JMT_FIELD_TEXTBOX id="JobTitle" value={JobTitle} setter={setJobTitle} caption="JobTitle" unit=""/>
                <JMT_FIELD_TEXTBOX id="Dept" value={Dept} setter={setDept} caption="Dept" unit=""/>
                <JMT_FIELD_TEXTBOX id="Cell" value={Cell} setter={setCell} caption="Cell" unit=""/>
                <JMT_FIELD_TEXTBOX id="Email" value={Email} setter={setEmail} caption="Email" unit=""/>
                <JMT_FIELD_TEXTBOX id="TelegramID" value={TelegramID} setter={setTelegramID} caption="TelegramID" unit=""/>
                <JMT_FIELD_TEXTBOX id="Password" value={Password} setter={setPassword} caption="Password" unit=""/>
                <br/>
                <Button label={title} onClick={handleClick} className="p-button-sm p-button-warning mr-2 mb-2"/>
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

