import React from 'react';

import { Panel } from 'primereact/panel';

export const AppDashboard = (props) => {

    return (
        <div className="grid">
            <div className="col-12 lg:col-4">
                <Panel header="Password" toggleable>
                </Panel>
                <br/>
                <Panel header="Timesheet" toggleable>
                </Panel>
            </div>        
            <div className="col-12 lg:col-4">
                <Panel header="Covid Stats" toggleable>
                </Panel>
                <br/>
                <Panel header="Leave" toggleable>
                </Panel>
            </div>        
            <div className="col-12 lg:col-4">
                <Panel header="Sales Stats" toggleable>
                </Panel>
                <br/>
                <Panel header="Todo List" toggleable>
                </Panel>
                <br/>
                <Panel header="Downloads" toggleable>
                    {/* <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/AfromixDesignEdge.exe'>Design Edge NEW</a> */}
                    {/* <br/> */}
                    {/* <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/AfromixDesignEdge1.zip'>Design Edge</a> */}
                    {/* <br/> */}
                    {/* <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/amx_cordova.apk'>Android App</a> */}
                    {/* <br/> */}
                    {/* <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/amx.apk'>Android App Barto</a> */}
                    {/* <br/> */}
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/amx_new.apk'>Android App NEW</a>
                    <br/>
                    Stock System                
                    <br/>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/AfromixStock.exe'>EXE</a>
                    <br/>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/Interop.bpac.dll'>DLL</a>
                    <br/>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/pd9700w603avt64us.exe'>Driver</a>
                    <br/>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/bsdkw32010eu.exe'>SDK</a>
                    <br/>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/pew52011us.exe'>Editor</a>
                    <br/>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.afromixcrm.co.za/amx2/assets/vb/jmt.lbx'>Barcode</a>
                </Panel>
            </div>        
        </div>
    );
}
