import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_DATEBOX              } from '../../components/jmt_field_datebox.jsx';
import { JMT_XLSX_DIALOG                } from '../../components/jmt_xlsx_dialog';


export const POPUP_WAREHOUSE_TRIP_SHEET_REPORTS = ({}) => {
    const endpoint = "/warehouse/get/trip/sheet";
    const title = "Trip Sheet Reports"
    const [FromDate, setFromDate] = useState(new Date());
    const [ToDate, setToDate] = useState(new Date());

    const [displayBasic, setDisplayBasic] = useState(false); 
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({});
    
    const handlePopup = () => {
        setDisplayBasic(true);
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        console.log("refresh");
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{}} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_DATEBOX id="FromDate" value={FromDate} setter={setFromDate} caption="From Date" unit=""/>
            <JMT_FIELD_DATEBOX id="ToDate" value={ToDate} setter={setToDate} caption="To Date" unit=""/>
            <br/>
            <JMT_XLSX_DIALOG title="Detail" endpoint="/warehouse/get/trip/sheet/detail" payload={{"from_date":FromDate, "to_date":ToDate}}/>

            {/* <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.invoice_details} 
                loading={axios_loading} 
                dataKey="LineID" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="StockID" field="StockID" />
                <Column header="Description" field="Description" />
                <Column header="Qty" field="Qty" />
            </DataTable> */}
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}



