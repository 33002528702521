import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                          } from '../../components/axios'
import { JMT_INPUTGROUP_PAGINATOR       } from '../../components/jmt_inputgroup_paginator'
import { JMT_INPUTGROUP_FILTER          } from '../../components/jmt_inputgroup_filter'
import { JMT_INPUTGROUP_DROPDOWN        } from '../../components/jmt_inputgroup_dropdown'
import { JMT_INPUTGROUP_SORTER          } from '../../components/jmt_inputgroup_sorter'

export const POPUP_LOCATION_LOOKUP = ({caption, id, value, setter}) => {
    const endpoint = '/location/get/list';
    const PK = "LocationID"
    const [displayBasic, setDisplayBasic] = useState(false);

    const localParamFilterStr = localStorage.getItem("RBLF_"+PK+"_LocalParamFilterStr");
    const localParamPage = localStorage.getItem("RBLF_"+PK+"_LocalParamPage");
    const localParamStatus = localStorage.getItem("RBLF_"+PK+"_LocalParamStatus");
    const localParamSortField = localStorage.getItem("RBLF_"+PK+"_LocalParamSortField");
    const localParamSortOrder = localStorage.getItem("RBLF_"+PK+"_LocalParamSortOrder");

    const [paramFilterStr, setParamFilterStr] = useState(localParamFilterStr !== null ? localParamFilterStr : "");
    const [paramPage, setParamPage] = useState(localParamPage !== null ? localParamPage : 1);
    const [paramStatus, setParamStatus] = useState(localParamStatus !== null ? localParamStatus : "undefined");
    const [paramSortField, setParamSortField] = useState(localParamSortField !== null ? localParamSortField : PK);
    const [paramSortOrder, setParamSortOrder] = useState(localParamSortOrder !== null ? localParamSortOrder : 1);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        data:{},
        columns:[],
        status:[]
    });

    const handlePopup = () => {
        setDisplayBasic(true);
        handleRefresh();
    }

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        if(res.data.data.length === 0 ){
            setParamPage(1); 
        }
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(()=>{
        set_axios_loading(true);
        localStorage.setItem("RBLF_"+PK+"_LocalParamFilterStr", paramFilterStr);
        localStorage.setItem("RBLF_"+PK+"_LocalParamPage", paramPage);
        localStorage.setItem("RBLF_"+PK+"_LocalParamStatus", paramStatus);
        localStorage.setItem("RBLF_"+PK+"_LocalParamSortField", paramSortField);
        localStorage.setItem("RBLF_"+PK+"_LocalParamSortOrder", paramSortOrder);
        handleRefresh();
    }, [paramPage, paramFilterStr, paramStatus, paramSortField, paramSortOrder])
 
    const handleSelection = (e) => {
        localStorage.setItem("RBLF_"+PK, e.value.LocationID);
        setter(e.value.LocationID);
        setDisplayBasic(false);
    }

    return (
        <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                <AXIOS 
                    endpoint={endpoint}  
                    payload={{
                        "filter":paramFilterStr,
                        "page":paramPage, 
                        "page_limit":20, 
                        "filter_status": paramStatus, 
                        "sort_field": paramSortField, 
                        "sort_order": paramSortOrder
                    }} 
                    trigger={axios_trigger} 
                    handleSuccess={axios_success} 
                />
                <Dialog 
                    header={"Lookup "+caption} 
                    visible={displayBasic} 
                    position={"top"} 
                    style={{ backgroundColor:'red', width: '90vw' }} 
                    modal 
                    onHide={() => setDisplayBasic(false)} 
                >
                    <div className="grid">
                        <div className="col-12 lg:col-3">
                            <JMT_INPUTGROUP_FILTER filter={paramFilterStr} setFilter={setParamFilterStr} />
                        </div>
                        <div className="col-12 lg:col-3">
                            <JMT_INPUTGROUP_DROPDOWN records={axios_data.status} value={paramStatus} setter={setParamStatus}/>
                        </div>
                        <div className="col-12 lg:col-3">
                            <JMT_INPUTGROUP_SORTER cols={axios_data.columns} sortField={paramSortField} setSortField={setParamSortField} sortOrder={paramSortOrder} />
                        </div>
                        <div className="col-12 lg:col-3">
                            <JMT_INPUTGROUP_PAGINATOR records={axios_data.filtered_records} records_per_page={20} page={paramPage} setter={setParamPage} />
                        </div>
                    </div>
                    <DataTable 
                        value={axios_data.data} 
                        size="small" 
                        selectionMode="single" 
                        onSelectionChange={handleSelection} 
                        responsiveLayout="scroll" 
                        sortField={paramSortField} 
                        sortOrder={paramSortOrder} 
                        onSort={(e) => {
                            setParamSortField(e.sortField);
                            setParamSortOrder(e.sortOrder);
                        }}
                        showGridlines
                        loading={axios_loading}
                    >
                    {
                        axios_data.columns.map((col, i) => {
                            return <Column key={col} on field={col} header={col} sortable />;
                        })
                    }
                    </DataTable>
                </Dialog>
                <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                <InputText  
                    style={{border:'0px', width:'100%', padding:'2px', backgroundColor:'lime'}} 
                    id={id} 
                    value={value} 
                    autoComplete="off"
                    placeholder=""  
                    onClick={handlePopup}
                />
            </div>
        </div>
    );
}

