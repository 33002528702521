import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
        
export const POPUP_WAREHOUSE_BARCODE_DRIVER = () => {
    const endpoint = "/warehouse/get/barcodes/driver";
    const title = "Barcodes Driver"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState(null);
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({});
    const [ImageType, setImageType] = useState({ImageType: ''}); 

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        if(ImageType.ImageType!==''){
            set_axios_trigger(new Date());
            set_axios_loading(true);
        }
    }, [ImageType]);

    return (
        <>
        <AXIOS 
            endpoint={endpoint}  
            payload={{
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            position={"top"} 
            onHide={() => setDisplayBasic(false)}
        >
            {
                axios_loading
                ? 
                <>
                    <Skeleton></Skeleton>
                    <br/>
                    <Skeleton></Skeleton>
                </>
                :
                <center>
                    <img alt="No Data" width="400px" src={"data:image/png;base64,"+axios_data.cage1_base64}/>
                    <h2>
                        {axios_data.cage1_text}
                    </h2>
                    <img alt="No Data" width="400px" src={"data:image/png;base64,"+axios_data.cage2_base64}/>
                    <h2>
                        {axios_data.cage2_text}
                    </h2>
                    <img alt="No Data" width="400px" src={"data:image/png;base64,"+axios_data.cage3_base64}/>
                    <h2>
                        {axios_data.cage3_text}
                    </h2>
                    <img alt="No Data" width="400px" src={"data:image/png;base64,"+axios_data.cage4_base64}/>
                    <h2>
                        {axios_data.cage4_text}
                    </h2>
                    <img alt="No Data" width="400px" src={"data:image/png;base64,"+axios_data.cage5_base64}/>
                    <h2>
                        {axios_data.cage5_text}
                    </h2>
                    <img alt="No Data" width="400px" src={"data:image/png;base64,"+axios_data.dispatch_driver_base64}/>
                    <h2>
                        {axios_data.dispatch_driver_text}
                    </h2>
                </center>
            }

        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}