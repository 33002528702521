import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                                      } from '../../components/axios'
import { JMT_FIELD_LABEL                            } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                             } from '../../components/jmt_api_button';
import { POPUP_WAREHOUSE_ORDER_PROGRESS_DETAILS     } from './warehouse_order_progress_details';


export const POPUP_WAREHOUSE_ORDER_PROGRESS = ({customer_id}) => {
    const endpoint = "/warehouse/get/order/progress";
    const title = "Order Progress"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "invoices": [], 
    });

    const handlePopup = () => {
        setDisplayBasic(true);
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        console.log("refresh");
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "customer_id": customer_id
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.invoices} 
                loading={axios_loading} 
                dataKey="OrderNo" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="InvoiceDate" field="InvoiceDate" />
                <Column header="OrderNo" field="OrderNo" />
                <Column header="InvoiceNo" field="InvoiceNo" />
                <Column header="PickingStatus" field="PickingStatus" />
                <Column header="Order Details" body={(rowdata)=>{return <POPUP_WAREHOUSE_ORDER_PROGRESS_DETAILS invoice_id={rowdata.InvoiceID} />}}/>
            </DataTable>
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}



