import React from 'react';

import { Badge } from 'primereact/badge';
import { Dropdown } from 'primereact/dropdown';

export const JMT_INPUTGROUP_DROPDOWN = ({records, value, setter}) => {

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div>
                    <div>{option.value} - <Badge className="mr-2" size="large" value={option.counter} severity="danger"></Badge></div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const countryOptionTemplate = (option) => {
        return (
            <div>
                <div>{option.value} - <Badge className="mr-2" size="large" value={option.counter} severity="danger"></Badge></div>
            </div>
        );
    }


    return (
        <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    Status
                </span>
                <Dropdown 
                    value={value} 
                    options={records} 
                    onChange={(e) => setter(e.value)} 
                    optionLabel="name" 
                    showClear 
                    scrollHeight="700px"
                    valueTemplate={selectedCountryTemplate} 
                    itemTemplate={countryOptionTemplate} 
                />                        
                <span className="p-inputgroup-addon">
                    <i className="pi pi-filter"></i>
                </span>
            </div>
        </div>
    );
}

