import React from 'react';

export const JMT_FIELD_LABEL = ({ caption, value, color='white'}) => {

    return (
        <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                <span style={{border:'0px', width:'100%', padding:'2px', backgroundColor: color }}>{value}</span>
            </div>
        </div>
    );
}

