import React from 'react';
import { Dropdown } from 'primereact/dropdown';

export const JMT_FIELD_DROPDOWN = ({ caption, id, value, list, error=false, setter, changed_event, has_filter=false }) => {

    return (
        <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
            <div className="p-inputgroup border-bottom-0">
                {
                    !error
                ?
                <>
                    <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                    <Dropdown 
                        style={{border:'0px', width:'100%', padding:'2px', backgroundColor:'lavender'}} 
                        id={id} 
                        value={value} 
                        onChange={(e) => {
                            console.log(e.value); 
                            setter(e.value); 
                                if(changed_event != null){
                                    changed_event();
                                }
                            }} 
                        options={list} 
                        optionLabel={id} 
                        placeholder="Select" 
                        filter={has_filter}
                    />
                </>
                :
                <>
                    <span style={{width:'300px', padding:'2px'}} >{caption}</span>
                    <Dropdown 
                        style={{border:'0px', width:'100%', padding:'2px', backgroundColor:'red'}} 
                        id={id} 
                        value={value} 
                        onChange={(e) => {
                            console.log(e.value); 
                            setter(e.value); 
                                if(changed_event != null){
                                    changed_event();
                                }
                            }} 
                        options={list} 
                        optionLabel={id} 
                        placeholder="Select" 
                        filter={has_filter}
                    />
                </>    
            }
            </div>
        </div>
    );
}

