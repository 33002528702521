import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN             } from '../../components/jmt_field_dropdown'

export const POPUP_QUEUE_LOAD = () => {
    const endpoint = "/queue/load/queue";
    const title = "Load Queue"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [Customers, setCustomers] = useState("");
    const [QeueType, setQeueType] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "customer_list":Customers,
                "query_type":QeueType,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_TEXTBOX id="Customers" value={Customers} setter={setCustomers} caption="Customers"/>
            <JMT_FIELD_TEXTBOX id="QeueType" value={QeueType} setter={setQeueType} caption="QeueType"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>  
        </>
    );
}
