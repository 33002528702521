import React from 'react';
import { Button } from 'primereact/button';
export const JMT_INPUTGROUP_PAGINATOR = ({ records, records_per_page, page, setter }) => {
    var selectedPage = page*1;
    const maxPages = Math.ceil(records / records_per_page);
    const recordOffset = (records_per_page*(selectedPage-1))
    const firstRecord = recordOffset+1;
    var lastRecord = recordOffset+records_per_page;
    if(lastRecord > records){
        lastRecord = records;
    }
    const handleClickF = () => {
        setter(1)
    }
    const handleClickP = () => {
        var newPage = selectedPage - 1;
        if(newPage < 1){
            newPage = 1;
        }
        setter(newPage);
    }
    const handleClick1 = (e) => {
        setter(e);
    }
    const handleClickN = () => {
        var newPage = selectedPage + 1;
        if(newPage > maxPages){
            newPage = maxPages;
        }
        setter(newPage);
    }
    const handleClickL = () => {
        setter(maxPages);
    }
    const columns = [1, 2, 3, 4, 5];
    const pageButtons = columns.map((col,i) => {
        if(col <= maxPages){
            var offset = 3;
            if(selectedPage === 1             ){offset = 1;}
            if(selectedPage === 2             ){offset = 2;}
            if(selectedPage === maxPages - 1  ){offset = 4;}
            if(selectedPage === maxPages - 0  ){offset = 5;}
            var pageButton = col;
            if(maxPages >= 5){
                pageButton = selectedPage - offset + col;
            }
            if(selectedPage === pageButton){
                return <Button 
                    key={i} 
                    label={pageButton} 
                    className="p-button-info" 
                    onClick={(e) => handleClick1(pageButton)}
                    />
            } else {
                return <Button 
                    key={i} 
                    style={{backgroundColor:'white', color:'blue'}} 
                    label={pageButton} 
                    className="p-button-blank" 
                    onClick={(e) => handleClick1(pageButton)}
                    />
            }
        }
        return <span key={i}/>
    });
    return (
        <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon" onClick={handleClickF}><i className="pi pi-angle-double-left"></i></span>
                <span className="p-inputgroup-addon" onClick={handleClickP}><i className="pi pi-angle-left"></i></span>
                {pageButtons}
                <span className="p-inputgroup-addon" onClick={handleClickN}><i className="pi pi-angle-right"></i></span>
                <span className="p-inputgroup-addon" onClick={handleClickL}><i className="pi pi-angle-double-right"></i></span>
            </div>
            Showing {firstRecord} to {lastRecord} of {records}  
        </div>
    );
}

