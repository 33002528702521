import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN             } from '../../components/jmt_field_dropdown'

export const POPUP_LEAD_CREATE = ({ setter, refresh}) => {
    const endpoint = "/lead/set/add";
    const title = "Create"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [CustomerName, setCustomerName] = useState("");
    const [CustomerCode, setCustomerCode] = useState("");
    const [Balance, setBalance] = useState("");
    const [Terms, setTerms] = useState("");
    const [Address, setAddress] = useState("");
    const [VenueType, setVenueType] = useState("");
    const [Area, setArea] = useState("");
    const [Tel, setTel] = useState("");
    const [Tel2, setTel2] = useState("");
    const [Fax, setFax] = useState("");
    const [Fax2, setFax2] = useState("");
    const [Email, setEmail] = useState("");
    const [OnHold, setOnHold] = useState("");
    const [BusinessType, setBusinessType] = useState("");
    const [KeyAccount, setKeyAccount] = useState("");
    const [GroupHeadOffice, setGroupHeadOffice] = useState("");
    const [GroupAccount, setGroupAccount] = useState("");
    const [Contact_Person, setContact_Person] = useState("");
    const [Rep, setRep] = useState("");
    const [PreferredDay, setPreferredDay] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        setter(res.data.lead_id);
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "customer_name":CustomerName,
                "customer_code":CustomerCode,
                "balance":Balance,
                "terms":Terms,
                "address":Address,
                "venue_type":VenueType,
                "area":Area,
                "tel":Tel,
                "tel2":Tel2,
                "fax":Fax,
                "fax2":Fax2,
                "email":Email,
                "on_hold":OnHold,
                "business_type":BusinessType,
                "key_account":KeyAccount,
                "group_head_office":GroupHeadOffice,
                "group_account":GroupAccount,
                "contact_person":Contact_Person,
                "rep":Rep,
                "preferredDay":PreferredDay,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_TEXTBOX id="CustomerName" value={CustomerName} setter={setCustomerName} caption="CustomerName"/>
            <JMT_FIELD_TEXTBOX id="CustomerCode" value={CustomerCode} setter={setCustomerCode} caption="CustomerCode"/>
            <JMT_FIELD_TEXTBOX id="Balance" value={Balance} setter={setBalance} caption="Balance"/>
            <JMT_FIELD_TEXTBOX id="Terms" value={Terms} setter={setTerms} caption="Terms"/>
            <JMT_FIELD_TEXTBOX id="Address" value={Address} setter={setAddress} caption="Address"/>
            <JMT_FIELD_TEXTBOX id="VenueType" value={VenueType} setter={setVenueType} caption="VenueType"/>
            <JMT_FIELD_TEXTBOX id="Area" value={Area} setter={setArea} caption="Area"/>
            <JMT_FIELD_TEXTBOX id="Tel" value={Tel} setter={setTel} caption="Tel"/>
            <JMT_FIELD_TEXTBOX id="Tel2" value={Tel2} setter={setTel2} caption="Tel2"/>
            <JMT_FIELD_TEXTBOX id="Fax" value={Fax} setter={setFax} caption="Fax"/>
            <JMT_FIELD_TEXTBOX id="Fax2" value={Fax2} setter={setFax2} caption="Fax2"/>
            <JMT_FIELD_TEXTBOX id="Email" value={Email} setter={setEmail} caption="Email"/>
            <JMT_FIELD_TEXTBOX id="OnHold" value={OnHold} setter={setOnHold} caption="OnHold"/>
            <JMT_FIELD_TEXTBOX id="BusinessType" value={BusinessType} setter={setBusinessType} caption="BusinessType"/>
            <JMT_FIELD_TEXTBOX id="KeyAccount" value={KeyAccount} setter={setKeyAccount} caption="KeyAccount"/>
            <JMT_FIELD_TEXTBOX id="GroupHeadOffice" value={GroupHeadOffice} setter={setGroupHeadOffice} caption="GroupHeadOffice"/>
            <JMT_FIELD_TEXTBOX id="GroupAccount" value={GroupAccount} setter={setGroupAccount} caption="GroupAccount"/>
            <JMT_FIELD_TEXTBOX id="Contact_Person" value={Contact_Person} setter={setContact_Person} caption="Contact_Person"/>
            <JMT_FIELD_TEXTBOX id="Rep" value={Rep} setter={setRep} caption="Rep"/>
            <JMT_FIELD_TEXTBOX id="PreferredDay" value={PreferredDay} setter={setPreferredDay} caption="PreferredDay"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>  
        </>
    );
}
