import React, { useState, useEffect } from 'react';

import { Skeleton } from 'primereact/skeleton';

import { AXIOS                              } from '../components/axios'

export const JMT_TIMESHEET = ({ username, onDate }) => {
    const onDateDate = new Date(onDate);
    const onDateDay = onDateDate.getDay();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const onDateDayString = days[onDateDay];

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        base64:"",
    });

    useEffect(()=>{
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }, [username, onDate]);

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }
    return (
        <>
            <AXIOS 
                endpoint='/user/png/timesheet'  
                payload={{
                    "UserName":username,
                    "Date":onDate, 
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />        
            <span>{username}</span>
            <span style={{float:'right'}}>{onDateDayString} - {onDate}</span>                    
            <br/>
            {
                axios_loading
                ? 
                <>
                    <Skeleton></Skeleton>
                    <br/>
                    <Skeleton></Skeleton>
                </>
                :
                <img 
                    style={{width:"100%"}} 
                    alt="No Data" 
                    src={"data:image/png;base64,"+axios_data.base64}
                />
            }
        </>
    );
}

