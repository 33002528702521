import React from 'react';

import { InputText } from 'primereact/inputtext';

export const JMT_INPUTGROUP_FILTER = ({filter, setFilter}) => {

    return (
        <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
                Filter
            </span>
            <InputText value={filter} onChange={(e) => setFilter(e.target.value)}  autoFocus/>
            <span className="p-inputgroup-addon">
                <i className="pi pi-search"></i>
            </span>
        </div>
    );
}

