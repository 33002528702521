import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import AuthProvider from './context/AuthProvider';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
// CSS
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';


// import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
// BASE
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppLogin } from './AppLogin';
import { AppDashboard } from './AppDashboard';
import { AppSettings } from './AppSettings';
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
// ADMIN
import { Page_Role                              } from './pages/role/role';
import { Page_User                              } from './pages/user/user';
import { Page_Admin                             } from './pages/admin/admin';
import { Page_Ar                                } from './pages/ar/ar';
import { Page_Customer                          } from './pages/customer/customer';
import { Page_HorOrNot                          } from './pages/hotornot/hotornot';
import { Page_Location                          } from './pages/location/location';
import { Page_Lead                              } from './pages/lead/lead';
import { Page_Queue                             } from './pages/queue/queue';
import { Page_Pallet                            } from './pages/pallet/pallet';
import { Page_Pastel                            } from './pages/pastel/pastel';
import { Page_Warehouse                         } from './pages/warehouse/warehouse';
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
const App = () => {
    const { authTokenObj, authAPISObj } = useContext(AuthProvider)
    const [ authAPIS ] = authAPISObj;
    const [ authToken ] = authTokenObj;
    const [layoutMode] = useState('static');
    const [inputStyle] = useState('outlined');
    const [ripple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    const copyTooltipRef = useRef();

    const location = useLocation();

    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }
        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    const menuItems = [
        { label: 'Role',            icon: 'pi pi-fw pi-users',              required_endpoint: '/role/get/read',           to: '/role' },
        { label: 'User',            icon: 'pi pi-fw pi-users',              required_endpoint: '/user/get/read',                to: '/user' },
        { label: 'Admin',           icon: 'pi pi-fw pi-file-o',             required_endpoint: '/admin/get/read',               to: '/admin' },
        { label: 'AR',              icon: 'pi pi-fw pi-calendar',           required_endpoint: '/ar/get/read',                  to: '/ar' },
        { label: 'Customer',        icon: 'pi pi-fw pi-map',                required_endpoint: '/customer/get/read',            to: '/customer' },
        { label: 'HotOrNot',        icon: 'pi pi-fw pi-chart-pie',          required_endpoint: '/hotornot/get/read',            to: '/hotornot' },
        { label: 'Lead',            icon: 'pi pi-fw pi-map',                required_endpoint: '/lead/get/read',                to: '/lead' },
        { label: 'Location',        icon: 'pi pi-fw pi-align-justify',      required_endpoint: '/location/get/read',            to: '/location' },
        { label: 'Queue',           icon: 'pi pi-fw pi-map',                required_endpoint: '/queue/get/read',               to: '/queue' },
        { label: 'Pallet',          icon: 'pi pi-fw pi-align-justify',      required_endpoint: '/pallet/get/read',              to: '/pallet' },
        { label: 'Pastel',          icon: 'pi pi-fw pi-ticket',             required_endpoint: '/pastel/get/read',              to: '/pastel' },
        { label: 'Warehouse',       icon: 'pi pi-fw pi-box',                required_endpoint: '/warehouse/get/read',           to: '/warehouse' },
    ]
    var filtered = menuItems.filter(el => authAPIS.includes(el.required_endpoint));
    const menu = [
        {
            label: '',
            items: filtered
        },      
    ];
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {
                authToken === "" ? (
                <section>
                    <AppLogin/>
                </section>
                ) : (
                <section>
                    <Tooltip 
                        ref={copyTooltipRef} 
                        target=".block-action-copy" 
                        position="bottom" 
                        content="Copied to clipboard" 
                        event="focus" 
                    />
                    <AppTopbar 
                        onToggleMenuClick={onToggleMenuClick} 
                        mobileTopbarMenuActive={mobileTopbarMenuActive} 
                        onMobileTopbarMenuClick={onMobileTopbarMenuClick} 
                        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} 
                    />        
                    <div 
                        className="layout-sidebar" 
                        onClick={onSidebarClick}
                    >
                        <AppMenu 
                            model={menu} 
                            onMenuItemClick={onMenuItemClick} 
                        />
                        {/* {authScreens} */}
                    </div>
                    <div className="layout-main-container">
                        <div className="layout-main">
                            <Route path="/dashboard" exact render={() => <AppDashboard/>} />            
                            <Route path="/settings" exact render={() => <AppSettings/>} />            
                            {/* ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////////////////////////// */}
                            <Route path="/role" component={Page_Role} />
                            <Route path="/user" component={Page_User} />
                            <Route path="/admin" component={Page_Admin} />
                            <Route path="/ar" component={Page_Ar} />
                            <Route path="/customer" component={Page_Customer} />
                            <Route path="/hotornot" component={Page_HorOrNot} />
                            <Route path="/lead" component={Page_Lead} />
                            <Route path="/location" component={Page_Location} />
                            <Route path="/queue" component={Page_Queue} />
                            <Route path="/pallet" component={Page_Pallet} />
                            <Route path="/pastel" component={Page_Pastel} />
                            <Route path="/warehouse" component={Page_Warehouse} />
                            {/* <pre>{JSON.stringify(menuItems, null, 2) }</pre> */}
                            {/* <pre>{JSON.stringify(filtered, null, 2) }</pre> */}
                            {/* <pre>{JSON.stringify(authScreens, null, 2) }</pre> */}
                            
                            {/* ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            ////////////////////////////////////////////////////////////////////////////////////////
                            //////////////////////////////////////////////////////////////////////////////////////// */}
                        </div>            
                        <AppFooter/>
                    </div>
                    <CSSTransition 
                        classNames="layout-mask" 
                        timeout={{ enter: 200, exit: 200 }} 
                        in={mobileMenuActive} 
                        unmountOnExit
                    >
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>
                </section>
                )
            }
        </div>
    );
}
export default App;
