import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                  } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';
import { JMT_XLSX_DIALOG                } from '../../components/jmt_xlsx_dialog';

import { POPUP_LEAD_LOOKUP              } from './lookup';
import { POPUP_LEAD_CREATE              } from './lead_create';
import { POPUP_LEAD_PROCCESSED          } from './lead_processed';


export const Page_Lead = () => {
    const localSName = "Afromix_LeadID";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [LeadID, setLeadID] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "lead_data":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, LeadID);
        handleRefresh()
    }, [LeadID]);

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/lead/get/read' 
                payload={{
                    "lead_id":LeadID
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-6">
                <Panel header="Lead">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <POPUP_LEAD_LOOKUP id="LeadID" value={LeadID} setter={setLeadID} caption="LeadID"/>
                            <JMT_FIELD_LABEL id="CustomerName" value={axios_data.lead_data[0]?.CustomerName} caption="CustomerName"/>
                            <JMT_FIELD_LABEL id="CustomerCode" value={axios_data.lead_data[0]?.CustomerCode} caption="CustomerCode"/>
                            <JMT_FIELD_LABEL id="Balance" value={axios_data.lead_data[0]?.Balance} caption="Balance"/>
                            <JMT_FIELD_LABEL id="Terms" value={axios_data.lead_data[0]?.Terms} caption="Terms"/>
                            <JMT_FIELD_LABEL id="Address" value={axios_data.lead_data[0]?.Address} caption="Address"/>
                            <JMT_FIELD_LABEL id="VenueType" value={axios_data.lead_data[0]?.VenueType} caption="VenueType"/>
                            <JMT_FIELD_LABEL id="Area" value={axios_data.lead_data[0]?.Area} caption="Area"/>
                            <JMT_FIELD_LABEL id="Tel" value={axios_data.lead_data[0]?.Tel} caption="Tel"/>
                            <JMT_FIELD_LABEL id="Tel2" value={axios_data.lead_data[0]?.Tel2} caption="Tel2"/>
                            <JMT_FIELD_LABEL id="Fax" value={axios_data.lead_data[0]?.Fax} caption="Fax"/>
                            <JMT_FIELD_LABEL id="Fax2" value={axios_data.lead_data[0]?.Fax2} caption="Fax2"/>
                        </div>
                        <div className="col-12 lg:col-6">
                            <JMT_FIELD_LABEL id="Email" value={axios_data.lead_data[0]?.Email} caption="Email"/>
                            <JMT_FIELD_LABEL id="OnHold" value={axios_data.lead_data[0]?.OnHold} caption="OnHold"/>
                            <JMT_FIELD_LABEL id="BusinessType" value={axios_data.lead_data[0]?.BusinessType} caption="BusinessType"/>
                            <JMT_FIELD_LABEL id="KeyAccount" value={axios_data.lead_data[0]?.KeyAccount} caption="KeyAccount"/>
                            <JMT_FIELD_LABEL id="GroupHeadOffice" value={axios_data.lead_data[0]?.GroupHeadOffice} caption="GroupHeadOffice"/>
                            <JMT_FIELD_LABEL id="GroupAccount" value={axios_data.lead_data[0]?.GroupAccount} caption="GroupAccount"/>
                            <JMT_FIELD_LABEL id="Contact_Person" value={axios_data.lead_data[0]?.Contact_Person} caption="Contact_Person"/>
                            <JMT_FIELD_LABEL id="Rep" value={axios_data.lead_data[0]?.Rep} caption="Rep"/>
                            <JMT_FIELD_LABEL id="PreferredDay" value={axios_data.lead_data[0]?.PreferredDay} caption="PreferredDay"/>
                            <JMT_FIELD_LABEL id="Processed" value={axios_data.lead_data[0]?.Processed} caption="Processed"/>
                        </div>
                    </div>
                    <br/>
                    <POPUP_LEAD_CREATE setter={setLeadID} refresh={handleRefresh}/>
                    <POPUP_LEAD_PROCCESSED lead_id={LeadID} data={axios_data.lead_data[0]} refresh={handleRefresh}/>
                    <JMT_XLSX_DIALOG title="Lead Report" endpoint="/lead/xlsx/report" payload={{}}/>
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
