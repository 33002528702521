import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_USER_DELETE = ({ username, data, setter }) => {
    const endpoint = "/user/set/delete";
    const title = "Delete User"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setter("");
        setDisplayBasic(false);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "username":username, 
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
            />
            <Dialog 
                header={title} 
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <JMT_FIELD_LABEL id="UserName" value={username} caption="UserName"/>
                <JMT_FIELD_LABEL id="Name" value={data?.Name} caption="Name"/>
                <JMT_FIELD_LABEL id="Surname" value={data?.Surname} caption="Surname"/>
                <JMT_FIELD_LABEL id="JobTitle" value={data?.JobTitle} caption="JobTitle"/>
                <JMT_FIELD_LABEL id="Dept" value={data?.Dept} caption="Dept"/>
                <JMT_FIELD_LABEL id="Cell" value={data?.Cell} caption="Cell"/>
                <JMT_FIELD_LABEL id="Email" value={data?.Email} caption="Email"/>
                <JMT_FIELD_LABEL id="TelegramID" value={data?.TelegramID} caption="TelegramID"/>
                <br/>
                <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleClick} />
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

