import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Rating } from 'primereact/rating';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
// import { JMT_FIELD_LABEL } from '../../components/jmt_field_label';

export const POPUP_USER_ENDPOINT_BUILD = ({ UserName, UserData }) => {
    const endpoint = "/user/set/endpoint/build";
    const title = "User Endpoint Build"

    const [displayBasic, setDisplayBasic] = useState(false);

    const [endpoints, setEndpoints] = useState({
        "folders":[]
    });

    const [loading, setLoading] = useState(true);
    const [triggerEndpointUpdate, setTriggerEndpointUpdate] = useState("");
    const [Endpoint, setEndpoint] = useState({});
    const [method, setMethod] = useState("List");







    const [expandedRows, setExpandedRows] = useState([]);
    const headerTemplate = (data) => {
        // console.log(data)
        return (<span style={{ fontWeight:'bold' }}>{data.Folder}</span>);
    }





    const handlePopup = () => {
        setDisplayBasic(true);
        setTriggerEndpointUpdate(new Date());
        setMethod("List");
        setLoading(true);
    }

    const onRowGrant = (event) => {
        setEndpoint(event.value);
        setMethod("Grant");
        setTriggerEndpointUpdate(new Date());
        setLoading(true);
    }

    const onRowDeny = (event) => {
        setEndpoint(event.value);
        setMethod("Deny");
        setTriggerEndpointUpdate(new Date());
        setLoading(true);
    }

    const handleEndpointUpdateSuccess = (res) => {
        setEndpoints(res.data);
        setLoading(false);
        setEndpoint("");
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "username":UserName, 
                    "endpoint":Endpoint.Endpoint, 
                    "method":method 
                }} 
                trigger={triggerEndpointUpdate} 
                handleSuccess={handleEndpointUpdateSuccess} 
            />
            <Dialog 
                header={title} 
                style={{ width: '90vw' }} 
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                {/* <pre>{JSON.stringify(Endpoint, null, "\t")}</pre> */}
                <div className="grid">
                    <div className="col-12 lg:col-4">

                    </div>
                    <div className="col-12 lg:col-4">
                        <DataTable 
                            value={endpoints.endpoints_denied} 

                            rowGroupMode="subheader" 
                            groupRowsBy="Folder"
                            responsiveLayout="scroll"
                            expandableRowGroups 
                            expandedRows={expandedRows} 
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowGroupHeaderTemplate={headerTemplate} 


                            selectionMode="single" 
                            onSelectionChange={onRowGrant} 
                            dataKey="RoleID" 
                            rowHover 
                            loading={loading}
                            size="small"
                        >
                            <Column field="Folder" header="Folder"/>
                            <Column field="Endpoint" header="Endpoint"  sortable/>
                        </DataTable>
                        {/* <pre>{JSON.stringify(endpoints, null, "\t")}</pre> */}
                    </div>
                    <div className="col-12 lg:col-4">
                        <DataTable 
                            value={endpoints.endpoints_granted} 

                            rowGroupMode="subheader" 
                            groupRowsBy="Folder"
                            responsiveLayout="scroll"
                            expandableRowGroups 
                            expandedRows={expandedRows} 
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowGroupHeaderTemplate={headerTemplate} 



                            selectionMode="single" 
                            onSelectionChange={onRowDeny} 
                            dataKey="RoleID" 
                            rowHover 
                            loading={loading}
                            size="small"
                        >
                            <Column field="Folder" header="Folder"/>
                            <Column field="Endpoint" header="Endpoint"  sortable/>
                        </DataTable>
                        {/* <pre>{JSON.stringify(endpoints, null, "\t")}</pre> */}
                    </div>
                </div>
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

