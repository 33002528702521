import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_SPAN                 } from '../../components/jmt_api_span';
import { JMT_FIELD_TEXTBOX                  } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DATEBOX                  } from '../../components/jmt_field_datebox';


export const POPUP_PALLET_LINE_UPDATE = ({pallet_id, data, refresh}) => {
    const endpoint = "/pallet/set/line/update";
    const title = "Pallet Line Update"

    const [Qty, setQty] = useState("");
    const [BatchNo, setBatchNo] = useState("");
    const [ExpiryDate, setExpiryDate] = useState("");

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const handlePopup = () => {
        setDisplayBasic(true);
        setQty(data?.Qty);
        setBatchNo(data?.BatchNo);
        setExpiryDate(data?.ExpiryDate);
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "pallet_id":pallet_id,
                "pallet_line_id":data.PalletLineID,
                "qty":Qty, 
                "expiry_date":ExpiryDate
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="PalletID" value={pallet_id} caption="PalletID"/>
            <JMT_FIELD_LABEL id="PalletLineID" value={data.PalletLineID} caption="PalletLineID"/>
            <JMT_FIELD_LABEL id="StockID" value={data.StockID} caption="StockID"/>
            <JMT_FIELD_LABEL id="Description" value={data.Description} caption="Description"/>
            <JMT_FIELD_TEXTBOX id="Qty" value={Qty} setter={setQty} caption="Qty" />
            <JMT_FIELD_LABEL id="BatchNo" value={data.BatchNo} caption="BatchNo"/>
            <JMT_FIELD_DATEBOX id="ExpiryDate" value={ExpiryDate} setter={setExpiryDate} caption="ExpiryDate" />
            <br/>
            <Button label="Update" className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />

        </Dialog>
        <JMT_API_SPAN icon="pi pi-pencil" api={endpoint} onclick={handlePopup}/>
        </>
    );
}
