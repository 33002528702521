import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                              } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_SPAN                 } from '../../components/jmt_api_span';


export const POPUP_PALLET_LINE_DELETE = ({pallet_id, data, refresh}) => {
    const endpoint = "/pallet/set/line/delete";
    const title = "Pallet Line Delete"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_data, set_axios_data] = useState({
        "data": [], 
    });

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "pallet_id":pallet_id,
                "pallet_line_id":data.PalletLineID,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="PalletID" value={pallet_id} caption="PalletID"/>
            <JMT_FIELD_LABEL id="PalletLineID" value={data.PalletLineID} caption="PalletLineID"/>
            <JMT_FIELD_LABEL id="StockID" value={data.StockID} caption="StockID"/>
            <JMT_FIELD_LABEL id="Description" value={data.Description} caption="Description"/>
            <JMT_FIELD_LABEL id="Qty" value={data.Qty} caption="Qty"/>
            <JMT_FIELD_LABEL id="BatchNo" value={data.BatchNo} caption="BatchNo"/>
            <JMT_FIELD_LABEL id="ExpiryDate" value={data.ExpiryDate} caption="ExpiryDate"/>
            <br/>
            <Button label="Delete" className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />

        </Dialog>
        <JMT_API_SPAN icon="pi pi-times" api={endpoint} onclick={handlePopup}/>
        </>
    );
}
