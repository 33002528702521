import React, { useState, useContext, useRef } from 'react';
import axios from 'axios'
import AuthProvider from './context/AuthProvider';
import { baseUrl } from './_config/config';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useHistory, useLocation } from 'react-router-dom';
import { topBarTitle } from './_config/config';

export const AppLogin = (props) => {
    let location = useLocation();
    const history = useHistory();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const { authTokenObj, authUserObj, authAPISObj, authScreensObj } = useContext(AuthProvider)
    const [ authToken, setAuthToken ] = authTokenObj;
    const [ authUser, setAuthUser ] = authUserObj;
    const [ authAPIS, setAuthAPIS ] = authAPISObj;
    const [ authScreens, setAuthScreens ] = authScreensObj;
    const toast = useRef();

    const handleLogin = async () => {
        const params = new URLSearchParams()
        params.append("username", username);
        params.append("password", password);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        try {
            console.log("Post");
            const response = await axios.post(baseUrl + '/login', params, config);
            console.log("Respo");
            console.log(response.data);
            setAuthUser(response.data.username);
            setAuthAPIS(response.data.apis);
            setAuthScreens(response.data.screens);
            setAuthToken(response.data.access_token);
            localStorage.setItem("afromixCrmAuthUser", response.data.username);
            localStorage.setItem("afromixCrmAuthAPIS", response.data.apis);
            localStorage.setItem("afromixCrmAuthScreens", response.data.screens);
            localStorage.setItem("afromixCrmAuthToken", response.data.access_token);
            console.log(authToken);
            console.log(authUser);
            console.log(authAPIS);
            console.log(authScreens);
            console.log("LOCATION");
        } catch (error) {
            setAuthUser("");
            setAuthAPIS("");
            setAuthScreens("");
            setAuthToken("");
            localStorage.setItem("afromixCrmAuthUser", "");
            localStorage.setItem("afromixCrmAuthAPIS", "");
            localStorage.setItem("afromixCrmAuthScreens", "");
            localStorage.setItem("afromixCrmAuthToken", "");
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Rejected', detail: "Login Error", life: 3000 });
        }            
    }   
    return (
        <div className="flex justify-content-center">
            <Toast ref={toast} />
            <div className="form-demo col-8 lg:col-4">
                <div className="surface-card p-4 shadow-2 border-round w-full lg:w-12">
                    <div className="text-center mb-5">
                        <div className="text-900 text-3xl font-medium mb-3">{topBarTitle} Login</div>
                    </div>
                    <div>
                        <label htmlFor="username" className="block text-900 font-medium mb-2">Username</label>
                        <InputText id="username" type="text" className="w-full mb-3" value={username} onChange={(e) => setUsername(e.target.value)}/>
                        <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                        <InputText id="password" type="password" className="w-full mb-3" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <div className="flex align-items-center justify-content-between mb-6">
                            <button className="p-link font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</button>
                        </div>
                        <Button label="Sign In" icon="pi pi-user" className="w-full" onClick={handleLogin} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AppLogin;