import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox.jsx';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_FIELD_DATEBOX              } from '../../components/jmt_field_datebox.jsx';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_AR_ADD_NOTE = ({ customer_id }) => {
    const endpoint = "/ar/add/note";
    const title = "Add note"
    const [NoteType, setNoteType] = useState("");
    const [NoteDate, setNoteDate] = useState(new Date());
    const [NoteValue, setNoteValue] = useState("");
    const [Note, setNote] = useState("");
    
    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setDisplayBasic(false);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "customer_id": customer_id,
                    "NoteType":NoteType, 
                    "NoteDate":NoteDate, 
                    "NoteValue":NoteValue, 
                    "Note": Note,
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
            />
            <Dialog 
                header={title}
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <JMT_FIELD_LABEL id="CustomerID" value={customer_id} caption="CustomerID" />
                <JMT_FIELD_TEXTBOX id="NoteType" value={NoteType} setter={setNoteType} caption="NoteType" unit=""/>
                <JMT_FIELD_DATEBOX id="NoteDate" value={NoteDate} setter={setNoteDate} caption="NoteDate" unit=""/>
                <JMT_FIELD_TEXTBOX id="NoteValue" value={NoteValue} setter={setNoteValue} caption="NoteValue" unit=""/>
                <JMT_FIELD_TEXTBOX id="Note" value={Note} setter={setNote} caption="Note" unit=""/>
                <br/>
                <Button label={title} onClick={handleClick} className="p-button-sm p-button-warning mr-2 mb-2"/>
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

