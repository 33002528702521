import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                                      } from '../../components/axios'
import { JMT_FIELD_LABEL                            } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                             } from '../../components/jmt_api_button';
import { POPUP_WAREHOUSE_BACK_ORDERS_DETAILS        } from './warehouse_back_orders_detail';


export const POPUP_WAREHOUSE_BACK_ORDERS = ({}) => {
    const endpoint = "/warehouse/back/orders";
    const title = "Back Orders"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "back_orders": [], 
    });

    const handlePopup = () => {
        setDisplayBasic(true);
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        console.log("refresh");
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{}} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.back_orders} 
                loading={axios_loading} 
                dataKey="StockID" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="StockID" field="StockID"/>
                <Column header="Description" field="Description"/>
                <Column header="StockCode" field="StockCode"/>
                <Column header="TotalQtyOnBackorder" field="TotalQtyOnBackorder"/>
                <Column header="QtyPallets" field="QtyPallets"/>
                <Column header="QtyJHB" field="QtyJHB"/>
                <Column header="QtyDBN" field="QtyDBN"/>
                <Column header="QtyCT" field="QtyCT"/>
                <Column header="QtyOnPO" field="QtyOnPO"/>
                <Column header="Details" body={(rowdata)=>{return <POPUP_WAREHOUSE_BACK_ORDERS_DETAILS stock_id={rowdata.StockID} />}}/>
            </DataTable>
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}



