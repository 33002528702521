import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

import { AXIOS                   } from '../components/axios'
import { JMT_API_BUTTON          } from './jmt_api_button'
import { JMT_API_SPAN          } from './jmt_api_span'

export const JMT_PDF_DIALOG = ({title, endpoint, payload, enabled=true, layout="inline"}) => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_data, set_axios_data] = useState("");

    const handlePopup = () => {
        set_axios_data("");
        setDisplayBasic(true);
        set_axios_trigger(new Date());
    }

    const handleRefresh = () => {
        set_axios_data("");
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        set_axios_data(res.data.base64_str);  
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={payload} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            style={{ width:'70vw', height:'90vh'}}
            onHide={() => setDisplayBasic(false)}
        >
            {
                axios_data === ""
                ?
                <ProgressBar mode="indeterminate" style={{ height: '16px' }}></ProgressBar>
                :
                <>
                    <Button label="Refresh" className="p-button-raised p-button-sm p-button-info mr-2 mb-2" onClick={handleRefresh} />


                    {/* <a href={`data:application/pdf;base64,${axios_data}`} download="your_filename.pdf">
                        <iframe title="PDF" src={`data:application/pdf;base64,${axios_data}`} height="95%" width="100%"></iframe>
                    </a> */}

                    <iframe title="PDF" src={`data:application/pdf;base64,${axios_data}`} height="95%" width="100%"></iframe>
                </>
            }

        </Dialog>
        {
            layout === "inline" 
            ?
            <JMT_API_BUTTON icon="pi pi-file-pdf" caption={title} api={endpoint} onclick={handlePopup} color="secondary" enabled={enabled}/>
            :
            <JMT_API_SPAN icon="pi pi-file-pdf" api={endpoint} onclick={handlePopup} color="secondary" enabled={enabled}/>
        }
        </>
    );
}
