import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN             } from '../../components/jmt_field_dropdown'

export const POPUP_LOCATION_CREATE = ({ setter }) => {
    const endpoint = "/location/set/add";
    const title = "Create"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [LocationID, setLocationID] = useState("");
    const [LocationType, setLocationType] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        setter(LocationID);
        setLocationID("");
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "location_id":LocationID,
                "location_type":LocationType.LocationType,
                "branch":"JHB"
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title}
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_TEXTBOX id="LocationID" value={LocationID} setter={setLocationID} caption="LocationID"/>
            <JMT_FIELD_DROPDOWN id="LocationType" list={[
                                        {LocationType: 'Bluk'},
                                        {LocationType: 'Floor'},
                                        {LocationType: 'Picking'},
                                        {LocationType: 'Samples'},
                                    ]} value={LocationType} setter={setLocationType} caption="LocationType" />
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>  
        </>
    );
}
