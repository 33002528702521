import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

import { POPUP_LOCATION_LOOKUP          } from '../location/lookup';


export const POPUP_PALLET_MOVE = ({pallet_id, location_id, refresh}) => {
    const endpoint = "/pallet/set/move";
    const title = "Pallet Move"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [LocationID, setLocationID] = useState("");

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const handlePopup = () => {
        setLocationID(location_id);
        setDisplayBasic(true);
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "pallet_id":pallet_id,
                "location_id_from":location_id,                 
                "location_id_to":LocationID
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="PalletID" value={pallet_id} caption="PalletID"/>
            <JMT_FIELD_LABEL id="LocationID" value={location_id} caption="LocationID From"/>
            <POPUP_LOCATION_LOOKUP id="LocationID" value={LocationID} setter={setLocationID} caption="LocationID To"/>
            <br/>
            <Button label="Move" className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />

        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
