import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

import { POPUP_LOCATION_LOOKUP          } from './lookup';
import { POPUP_LOCATION_CREATE          } from './location_create';
import { POPUP_LOCATION_UPDATE          } from './location_update';
import { POPUP_LOCATION_DELETE          } from './location_delete';
import { POPUP_LOCATION_CHANGE_BRANCH   } from './location_change_branch'


export const Page_Location = () => {
    const localSName = "RBLF_LocationID";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [LocationID, setLocationID] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "location":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, LocationID);
        handleRefresh()
    }, [LocationID]);

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/location/get/read' 
                payload={{
                    "location_id":LocationID
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="Location">
                    <POPUP_LOCATION_LOOKUP id="LocationID" value={LocationID} setter={setLocationID} caption="LocationID"/>
                    <JMT_FIELD_LABEL id="LocationType" value={axios_data.location[0]?.LocationType} caption="LocationType"/>
                    <JMT_FIELD_LABEL id="PalletRow" value={axios_data.location[0]?.PalletRow} caption="PalletRow"/>
                    <JMT_FIELD_LABEL id="PalletLevel" value={axios_data.location[0]?.PalletLevel} caption="PalletLevel"/>
                    <JMT_FIELD_LABEL id="PalletColumn" value={axios_data.location[0]?.PalletColumn} caption="PalletColumn"/>
                    <JMT_FIELD_LABEL id="PalletSize" value={axios_data.location[0]?.PalletSize} caption="PalletSize"/>
                    <JMT_FIELD_LABEL id="Active" value={axios_data.location[0]?.Active} caption="Active"/>
                    <br/>
                    <POPUP_LOCATION_CREATE setter={setLocationID} />
                    <POPUP_LOCATION_UPDATE location_id={LocationID} data={axios_data.location} refresh={handleRefresh}/>
                    <POPUP_LOCATION_DELETE location_id={LocationID} data={axios_data.location} setter={setLocationID}/>
                    <POPUP_LOCATION_CHANGE_BRANCH location_id={LocationID} data={axios_data.location} setter={setLocationID}/>
                    <JMT_PDF_DIALOG title="Location Barcode" endpoint="/location/pdf/barcode" payload={{"location_id": LocationID}}/>
                    <JMT_PDF_DIALOG title="Location Row Barcodes" endpoint="/location/pdf/row/barcodes" payload={{"pallet_row": axios_data.location[0]?.PalletRow}}/>
                </Panel>
            </div>
        </div>
    );
}
