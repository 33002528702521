import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_DROPDOWN             } from '../../components/jmt_field_dropdown';


export const POPUP_PLACE_UPDATE = ({ place_id, data, repdata, refresh }) => {
    const endpoint = "/hotornot/update/place";
    const title = "Update Place"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");
    const [Name, setName] = useState("");
    const [Note, setNote] = useState("");
    const [Rep, setRep] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
        setName(data?.Name);
    }
    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setDisplayBasic(false);
        refresh()
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "place_id":place_id, 
                    "name":Name,
                    "note":Note,
                    "rep": Rep.Rep
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
            />        
            <Dialog 
                header={title} 
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <JMT_FIELD_LABEL id="PlaceID" value={place_id} caption="PlaceID" />
                <JMT_FIELD_LABEL id="Name" value={Name} caption="Name" />
                <JMT_FIELD_TEXTBOX id="Note" value={Note} setter={setNote} caption="Note" />
                <JMT_FIELD_DROPDOWN caption="Rep" id="Rep" value={Rep} list={repdata} setter={setRep}/>
                <br/>
                {/* <pre>{JSON.stringify(repdata, null, 2) }</pre> */}
                <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleClick} />
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

