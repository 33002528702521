import React, { useContext } from 'react';
import AuthProvider from '../context/AuthProvider';

import { Button } from 'primereact/button';

export const JMT_API_BUTTON = ({ caption, api, onclick, icon, children, color, enabled=true }) => {
    const { authAPISObj } = useContext(AuthProvider)
    const [ authAPIS ] = authAPISObj;

    return (
        <>
        {
        authAPIS.includes(api) ? 
            enabled 
            ?
            <Button 
                    style={{ margin:'-1px', height:'22px' }}
                    icon={icon} 
                    label={caption}                 
                    className={`p-button-sm p-button-${color} mr-2 mb-2`} 
                    onClick={onclick} 
                >{children}</Button>
            :
            <Button 
                    style={{ margin:'-1px', height:'22px' }}
                    icon={icon} 
                    label={caption}                 
                    disabled                
                    className={`p-button-sm p-button-${color} mr-2 mb-2`} 
                    onClick={onclick} 
                >{children}</Button>
            :
                <></>
        }
        </>
    );
}

