import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Rating } from 'primereact/rating';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
// import { JMT_FIELD_LABEL } from '../../components/jmt_field_label';

export const POPUP_USER_ROLE_ENDPOINT_UPDATE = ({ UserName, UserData }) => {
    const endpoint = "/user/set/role/endpoint/update";
    const title = "Update Role Endpoint"

    const [displayBasic, setDisplayBasic] = useState(false);

    const [endpoints, setEndpoints] = useState({
        "endpoints_granted":[]
    });

    const [loading, setLoading] = useState(true);
    const [triggerEndpointUpdate, setTriggerEndpointUpdate] = useState("");
    const [Endpoint, setEndpoint] = useState({});
    const [method, setMethod] = useState("");
    const [folder, setFolder] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
        setTriggerEndpointUpdate(new Date());
        setMethod("List");
        setLoading(true);
    }

    const onRowGrant = (event) => {
        setEndpoint(event.value);
        setMethod("Grant");
        setTriggerEndpointUpdate(new Date());
        setLoading(true);
    }

    const onRowDeny = (event) => {
        setEndpoint(event.value);
        setMethod("Deny");
        setTriggerEndpointUpdate(new Date());
        setLoading(true);
    }

    const handleEndpointUpdateSuccess = (res) => {
        setEndpoints(res.data);
        setLoading(false);
        setEndpoint("");
    }




    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "username":UserName, 
                    "role_name":Endpoint.RoleName, 
                    "folder":folder, 
                    "method":method 
                }} 
                trigger={triggerEndpointUpdate} 
                handleSuccess={handleEndpointUpdateSuccess} 
            />
            <Dialog 
                header={title} 
                style={{ width: '90vw' }} 
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                {/* <pre>{JSON.stringify(endpoints.folders, null, "\t")}</pre> */}
                <div className="grid">
                    <div className="col-12 lg:col-4">
                        <h4>EndPoints</h4>
                        <DataTable 
                            value={endpoints.endpoints_granted} 
                            responsiveLayout="scroll"
                            
                            
                            dataKey="RoleName" 
                            rowHover 
                            loading={loading}
                            size="small"
                            rowGroupMode="subheader" 
                            groupRowsBy="Folder"
                            rowGroupHeaderTemplate={(data, options) => {
                            return (
                                <div style={{ margin:'0px', padding:'5px', backgroundColor:'#aaaaaa', color:'black' }}>
                                    {data.Folder}
                                </div>
                                );
                            }
                        } 
                        >
                            <Column field="Endpoint" header="Endpoint"  sortable/>
                            <Column field="Roles" header="Roles"  sortable/>
                        </DataTable>
                    </div>
                    <div className="col-12 lg:col-4">
                        <h4>Roles Denied</h4>
                        <DataTable 
                            value={endpoints.roles_denied} 
                            responsiveLayout="scroll"
                            selectionMode="single" 
                            onSelectionChange={onRowGrant} 
                            dataKey="RoleName" 
                            rowHover 
                            loading={loading}
                            size="small"
                        >
                            <Column field="RoleName" header="RoleName"  sortable/>
                        </DataTable>
                        {/* <pre>{JSON.stringify(endpoints, null, "\t")}</pre> */}
                    </div>
                    <div className="col-12 lg:col-4">
                    <h4>Roles Granted</h4>
                        <DataTable 
                            value={endpoints.roles_granted} 
                            responsiveLayout="scroll"
                            selectionMode="single" 
                            onSelectionChange={onRowDeny} 
                            dataKey="RoleName" 
                            rowHover 
                            loading={loading}
                            size="small"
                        >
                            <Column field="RoleName" header="RoleName"  sortable/>
                        </DataTable>
                        {/* <pre>{JSON.stringify(endpoints, null, "\t")}</pre> */}
                    </div>
                </div>
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

