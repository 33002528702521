import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                  } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';

// import { POPUP_AR_LOOKUP              } from './lookup';

export const Page_Admin = () => {
    const localSName = "Afromix_Username";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [Username, setUSername] = useState(initID);

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "data":[]
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, Username);
        handleRefresh()
    }, [Username]);

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/admin/get/read' 
                payload={{
                    "username":Username
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-4">
                <Panel header="Admin">
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
