import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_TIMESHEET                  } from '../../components/jmt_timesheet';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';
import { JMT_XLSX_DIALOG                } from '../../components/jmt_xlsx_dialog';
import { JMT_FIELD_DATEBOX              } from '../../components/jmt_field_datebox.jsx';

import { POPUP_CUSTOMER_LOOKUP          } from './lookup';
import { POPUP_CUSTOMER_NOTES           } from './customer_notes';


export const Page_Customer = () => {
    const localSName = "Afromix_CustomerID";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [CustomerID, setCustomerID] = useState(initID);
    const [FromDate, setFromDate] = useState(new Date());
    const [ToDate, setToDate] = useState(new Date());
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "customer_data":[],
        "customer_notes_data":[],
        "spend_over_Last_12_months_by_quarter": [],
        "spend_over_last_24_months_by_quarter": [],
        "spend_over_last_12_months_by_month": [],
        "spend_over_last_24_months_by_month": [],
        "spend_over_last_12_months_by_category_header": [],
        "spend_over_last_24_months_by_category_header": [],
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, CustomerID);
        handleRefresh()
    }, [CustomerID]);

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }
    const { basicOptions, horizontalOptions, multiAxisOptions, stackedOptions } = getLightTheme();

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/customer/get/read' 
                payload={{
                    "customer_id":CustomerID
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-8">
                <Panel header="Customer">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <POPUP_CUSTOMER_LOOKUP id="CustomerID" value={CustomerID} setter={setCustomerID} caption="CustomerID"/>
                            <JMT_FIELD_LABEL id="CustomerName" value={axios_data.customer_data[0]?.CustomerName} caption="CustomerName"/>
                            <JMT_FIELD_LABEL id="CustomerCode" value={axios_data.customer_data[0]?.CustomerCode} caption="CustomerCode"/>
                            <JMT_FIELD_LABEL id="Balance" value={axios_data.customer_data[0]?.Balance} caption="Balance"/>
                            <JMT_FIELD_LABEL id="Terms" value={axios_data.customer_data[0]?.Terms} caption="Terms"/>
                            <JMT_FIELD_LABEL id="Address" value={axios_data.customer_data[0]?.Address} caption="Address"/>
                            <JMT_FIELD_LABEL id="VenueType" value={axios_data.customer_data[0]?.VenueType} caption="VenueType"/>
                            <JMT_FIELD_LABEL id="Area" value={axios_data.customer_data[0]?.Area} caption="Area"/>
                            <JMT_FIELD_LABEL id="Tel" value={axios_data.customer_data[0]?.Tel} caption="Tel"/>
                            <JMT_FIELD_LABEL id="Tel2" value={axios_data.customer_data[0]?.Tel2} caption="Tel2"/>
                            <JMT_FIELD_LABEL id="Fax" value={axios_data.customer_data[0]?.Fax} caption="Fax"/>
                            <JMT_FIELD_LABEL id="Fax2" value={axios_data.customer_data[0]?.Fax2} caption="Fax2"/>
                            <br/>
                            <JMT_FIELD_DATEBOX id="FromDate" value={FromDate} setter={setFromDate} caption="From Date" unit=""/>
                            <JMT_FIELD_DATEBOX id="ToDate" value={ToDate} setter={setToDate} caption="To Date" unit=""/>
                        </div>
                        <div className="col-12 lg:col-6">
                            <JMT_FIELD_LABEL id="Email" value={axios_data.customer_data[0]?.Email} caption="Email"/>
                            <JMT_FIELD_LABEL id="OnHold" value={axios_data.customer_data[0]?.OnHold} caption="OnHold"/>
                            <JMT_FIELD_LABEL id="BusinessType" value={axios_data.customer_data[0]?.BusinessType} caption="BusinessType"/>
                            <JMT_FIELD_LABEL id="KeyAccount" value={axios_data.customer_data[0]?.KeyAccount} caption="KeyAccount"/>
                            <JMT_FIELD_LABEL id="GroupHeadOffice" value={axios_data.customer_data[0]?.GroupHeadOffice} caption="GroupHeadOffice"/>
                            <JMT_FIELD_LABEL id="GroupAccount" value={axios_data.customer_data[0]?.GroupAccount} caption="GroupAccount"/>
                            <JMT_FIELD_LABEL id="Contact_Person" value={axios_data.customer_data[0]?.Contact_Person} caption="Contact_Person"/>
                            <JMT_FIELD_LABEL id="Rep" value={axios_data.customer_data[0]?.Rep} caption="Rep"/>
                            <JMT_FIELD_LABEL id="PreferredDay" value={axios_data.customer_data[0]?.PreferredDay} caption="PreferredDay"/>
                        </div>
                    </div>
                    <br/>
                    <JMT_XLSX_DIALOG title="Product Sales" endpoint="/customer/xlsx/product/sales" payload={{"customer_id":CustomerID}}/>
                    <JMT_XLSX_DIALOG title="Processed Orders" endpoint="/customer/xlsx/processed/orders" payload={{"customer_id":CustomerID, "from_date":FromDate, "to_date":ToDate}}/>
                </Panel>
                <br/>
                <Panel header="Notes">
                    <DataTable 
                        style={{padding: '0px'}}  
                        value={axios_data.customer_notes_data} 
                        loading={axios_loading} 
                        dataKey="Logtime" 
                        rowHover 
                        showGridlines
                        size="small"
                        responsiveLayout="scroll"
                    >
                        <Column header="Logtime" field="Logtime" sortable/>
                        <Column header="UserName" field="UserName" sortable/>
                        <Column header="QueueType" field="QueueType" sortable/>
                        <Column header="Status" field="Status" sortable/>
                        <Column header="Note" field="Note" sortable/>
                    </DataTable>
                </Panel>
            </div>
            <div className="col-12 lg:col-6">
                <Panel header="Stats">
                    <div className="grid">
                        <div className="col-12 lg:col-4">
                            <h3>Spend Over Last 12 Months By Quarter</h3>
                            <Chart type="bar" data={axios_data.spend_over_Last_12_months_by_quarter} options={stackedOptions} />
                            <h3>Spend Over Last 24 Months By Quarter</h3>
                            <Chart type="bar" data={axios_data.spend_over_last_24_months_by_quarter} options={stackedOptions} />
                        </div>
                        <div className="col-12 lg:col-4">
                            <h3>Spend Over Last 12 Months By Month</h3>
                            <Chart type="bar" data={axios_data.spend_over_last_12_months_by_month} options={stackedOptions} />
                            <h3>Spend Over Last 24 Months By Month</h3>
                            <Chart type="bar" data={axios_data.spend_over_last_24_months_by_month} options={stackedOptions} />
                        </div>
                        <div className="col-12 lg:col-4">
                            <h3>Spend Over Last 12 Months By Category Header</h3>
                            <Chart type="bar" data={axios_data.spend_over_last_12_months_by_category_header} options={stackedOptions} />
                            <h3>Spend Over Last 24 Months By Category Header</h3>
                            <Chart type="bar" data={axios_data.spend_over_last_24_months_by_category_header} options={stackedOptions} />
                        </div>
                    </div>
                </Panel>
            </div>
            {axios_loading}
        </div>
    );
}
