import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                                      } from '../../components/axios'
import { JMT_FIELD_LABEL                            } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                             } from '../../components/jmt_api_button';
import { JMT_API_SPAN                               } from '../../components/jmt_api_span';


export const POPUP_WAREHOUSE_BACK_ORDERS_DETAILS = ({stock_id}) => {
    const endpoint = "/warehouse/back/orders/details";
    const title = "Back Orders Detail"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "back_orders_list":[],
        "open_po_list":[],
        "stock_on_pastel":[],
        "pallets":[],
    });

    const handlePopup = () => {
        setDisplayBasic(true);
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        console.log("refresh");
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "stock_id":stock_id, 
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <h4>Back Orders</h4>
            <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.back_orders_list} 
                loading={axios_loading} 
                dataKey="OrderID" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="OrderID" field="OrderID"/>
                <Column header="OrderNo" field="OrderNo"/>
                <Column header="InvoiceDate" field="InvoiceDate"/>
                <Column header="Customer" field="Customer"/>
                <Column header="Qty" field="Qty"/>
                <Column header="Warehouse" field="Warehouse"/>
            </DataTable>
            <br/>
            <h4>Open PO Orders</h4>
            <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.open_po_list} 
                loading={axios_loading} 
                dataKey="OrderNo" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="OrderNo" field="OrderNo"/>
                <Column header="DueDate" field="DueDate"/>
                <Column header="Qty" field="Qty"/>
            </DataTable>
            <br/>
            <h4>Stock on Pastel</h4>
            <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.stock_on_pastel} 
                loading={axios_loading} 
                dataKey="Description" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="QtyOnHand" field="QtyOnHand"/>
                <Column header="QtyJHB" field="QtyJHB"/>
                <Column header="QtyDBN" field="QtyDBN"/>
                <Column header="QtyCT" field="QtyCT"/>
            </DataTable>
            <br/>
            <h4>Pallets</h4>
            <DataTable 
                style={{padding: '0px'}}  
                value={axios_data.pallets} 
                loading={axios_loading} 
                dataKey="PalletID" 
                rowHover 
                showGridlines
                size="small"
                responsiveLayout="scroll"
            >
                <Column header="PalletID" field="PalletID"/>
                <Column header="LocationID" field="LocationID"/>
                <Column header="BatchNo" field="BatchNo"/>
                <Column header="Qty" field="Qty"/>
                <Column header="ExpiryDate" field="ExpiryDate"/>
            </DataTable>
        </Dialog>
        <JMT_API_SPAN icon="pi pi-plus-circle" api={endpoint} onclick={handlePopup}/>
        </>
    );
}



