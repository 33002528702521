import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTBOX                } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN               } from '../../components/jmt_field_dropdown'

export const POPUP_LOCATION_CHANGE_BRANCH = ({ location_id, data, refresh }) => {
    const endpoint = "/location/set/change/branch";
    const title = "Change branch"
    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");

    const [LocationType, setLocationType] = useState("");
    const [PalletRow, setPalletRow] = useState("");
    const [PalletLevel, setPalletLevel] = useState("");
    const [PalletSize, setPalletSize] = useState("");
    const [PalletColumn, setPalletColumn] = useState("");
    const [Branch, setBranch] = useState("");

    useEffect(() => {
        setLocationType(data[0]?.LocationType);
        setPalletRow(data[0]?.PalletRow);
        setPalletLevel(data[0]?.PalletLevel);
        setPalletSize(data[0]?.PalletSize);
        setPalletColumn(data[0]?.PalletColumn);
        setBranch({"Branch":data[0]?.Branch});
    }, [data]);
    
    const handlePopup = () => {
        setDisplayBasic(true);
        setBranch({"Branch":data[0]?.Branch});
    }

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "branch":"JHB",
                "location_id":location_id,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="LocationID" value={location_id} caption="LocationID"/>
            <JMT_FIELD_LABEL id="LocationType" value={LocationType} caption="LocationType" />
            <JMT_FIELD_LABEL id="PalletRow" value={PalletRow} caption="PalletRow"/>
            <JMT_FIELD_LABEL id="PalletLevel" value={PalletLevel} caption="PalletLevel"/>
            <JMT_FIELD_LABEL id="PalletColumn" value={PalletColumn} caption="PalletColumn"/>
            <JMT_FIELD_LABEL id="PalletSize" value={PalletSize} caption="PalletSize"/>
            <JMT_FIELD_DROPDOWN id="Branch" list={[
                                        {Branch: 'CT'},
                                        {Branch: 'DBN'},
                                        {Branch: 'JHB'},
                                        {Branch: 'RES'},
                                    ]} value={Branch} setter={setBranch} caption="Branch" />
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />
            {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
