import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { JMT_TIMESHEET              } from '../../components/jmt_timesheet'

export const POPUP_USER_TIMESHEETS = ({ username }) => {
    // const endpoint = "";
    const title = "More Timesheets"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [date, setDate] = useState(new Date(new Date().toISOString().substring(0, 10)));
    const [dates, setDates] = useState([]);

    const handlePopup = () => {
        setDisplayBasic(true);
    }


    useEffect(() => {        
        var fromDate = new Date(date);
        fromDate.setDate(fromDate.getDate()-date.getDay()+1);
        // console.log(fromDate);
        const getDaysArray = function(start) {
            var arr=[];
            for (let i=0;i<8;i++) {
                var dt=new Date(start);
                dt.setDate(dt.getDate()+i);
                arr.push(new Date(dt));
            }
            setDates(arr);
        };
        getDaysArray(fromDate);
    }, [date]);

    const dynamicTimeSheets = dates.map((date, i) => {
        var dateString = date.toISOString().split('T')[0];
        return <JMT_TIMESHEET key={date.toISOString()} username={username} onDate={dateString} />;
    });

    const monthNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    const yearNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />;
    }

    return (
        <>
            <Dialog 
                header={title} 
                visible={displayBasic} 
                style = {{ width:'600px' }}
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <pre>{JSON.stringify(date, null, "\t")}</pre>
                <pre>{JSON.stringify(dates, null, "\t")}</pre>
                    <div style={{borderBottom: '1px solid', padding:'0px'}} className="col-12 md:col-12">
                    <div className="p-inputgroup border-bottom-0">
                        <span style={{width:'300px', padding:'2px'}} >{"OnDate"}</span>
                            <Calendar 
                                id="navigatorstemplate" 
                                value={date} 
                                dateFormat="yy/mm/dd"
                                onChange={(e) => setDate(e.value)} 
                                monthNavigator 
                                yearNavigator 
                                yearRange="2010:2030"
                                monthNavigatorTemplate={monthNavigatorTemplate} 
                                yearNavigatorTemplate={yearNavigatorTemplate} 
                            />
                    </div>
                </div>
                <br/>
                {dynamicTimeSheets}
            </Dialog>
            <Button 
                label={title}
                className="p-button-raised p-button-sm p-button-info mr-2 mb-2" 
                onClick={handlePopup} 
            />
        </>
    );
}

