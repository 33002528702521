import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTBOX                } from '../../components/jmt_field_textbox';
import { JMT_FIELD_DROPDOWN               } from '../../components/jmt_field_dropdown'
import { JMT_FIELD_DATEBOX              } from '../../components/jmt_field_datebox.jsx';


export const POPUP_QUEUE_PROCESS_NO_ANSWER_3_TIMES = ({ queue_id, refresh }) => {
    const localSName = "LCBI_PreferredDay";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const endpoint = "/queue/set/process/no/answer/3/times";
    const title = "No Answer 3 Times"
    const [displayBasic, setDisplayBasic] = useState(false); 
    const [axios_trigger, set_axios_trigger] = useState("");

    const [Note, setNote] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleUpdate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint} 
            payload={{
                "queue_id": queue_id,
                "note":Note,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="QueueID" value={queue_id} caption="QueueID"/>
            <JMT_FIELD_TEXTBOX id="Note" value={Note} setter={setNote} caption="Note"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleUpdate} />
            {/* <pre>{JSON.stringify(data, null, "\t")}</pre> */}
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
