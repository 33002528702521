import React, { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';

import { AXIOS                                      } from '../../components/axios'
import { JMT_FIELD_LABEL                            } from '../../components/jmt_field_label';
import { JMT_API_BUTTON                             } from '../../components/jmt_api_button';
import { JMT_PDF_DIALOG                             } from '../../components/jmt_pdf_dialog';

import { POPUP_WAREHOUSE_PICKING_LOOKUP             } from './warehouse_picking_lookup';
import { POPUP_WAREHOUSE_PICKING_COMPLETE           } from './warehouse_picking_complete';
import { POPUP_WAREHOUSE_PANEL_UID_NOTES            } from './warehouse_panel_uid_notes';
import { POPUP_WAREHOUSE_PANEL_PASTEL_NOTES         } from './warehouse_panel_pastel_notes';


export const POPUP_WAREHOUSE_PICKING = ({ items }) => {
    const endpoint = "/warehouse/picking";
    const title = "Picking"

    const [UID, setUID] = useState("");
    const [OrderNo, setOrderNo] = useState("");
    const [InvoiceID, setInvoiceID] = useState("");
    const [Picker, setPicker] = useState("");
    const [PickingStatus, setPickingStatus] = useState("Weights");

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "notes": [], 
        "pastel_notes": [], 
    });

    const handlePopup = () => {
        setDisplayBasic(true);
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }
    
    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }
    
    const axios_success = (res) => {
        console.log("refresh");
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "uid":UID,
                "invoice_id":InvoiceID,
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal
            onHide={() => setDisplayBasic(false)}
        >
            <POPUP_WAREHOUSE_PICKING_LOOKUP id="UID" value={UID} setter={setUID} setterOrderNo={setOrderNo} setterInvoiceID={setInvoiceID} caption="UID" setterPicker={setPicker} PickingStatus={PickingStatus}refresh={handleRefresh}/>            
            <JMT_FIELD_LABEL id="OrderNo" value={OrderNo} caption="OrderNo"/>
            <JMT_FIELD_LABEL id="InvoiceID" value={InvoiceID} caption="InvoiceID"/>
            <JMT_FIELD_LABEL id="Picker" value={Picker} caption="Picker"/>
            <br/>
            <POPUP_WAREHOUSE_PICKING_COMPLETE uid={UID} picker={Picker} refresh={handleRefresh}/>
            <JMT_PDF_DIALOG title="Picking Slip" endpoint="/warehouse/picking/pdf/slip" payload={{"uid": UID}}/>
            <br/>
            <POPUP_WAREHOUSE_PANEL_UID_NOTES uid={UID} notes={axios_data.notes} loading={axios_loading} refresh={handleRefresh}/>
            <POPUP_WAREHOUSE_PANEL_PASTEL_NOTES pastelnotes={axios_data.pastel_notes} loading={axios_loading}/>
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup} enabled={items!=0}>
            {items == 0 ? <></> : <Badge value={items} className="mr-2" size="large" severity="danger"></Badge>}
        </JMT_API_BUTTON>            
        </>
    );
}



