import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AXIOS                          } from '../../components/axios'
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label';
import { JMT_FIELD_TEXTAREA             } from '../../components/jmt_field_textarea.jsx';

export const POPUP_PALLET_NOTE_ADD = ({pallet_id, data, refresh}) => {
    const endpoint = "/pallet/set/note/add";
    const title = "Note Add"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [axios_trigger, set_axios_trigger] = useState("");
    const [Note, setNote] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleCreate = () => {
        set_axios_trigger(new Date());
    }

    const axios_success = (res) => {
        refresh();
        setNote("");
        setDisplayBasic(false);
    }

    return (
        <>
        <AXIOS 
            endpoint={endpoint}
            payload={{
                "pallet_id":pallet_id,
                "note":Note
            }} 
            trigger={axios_trigger} 
            handleSuccess={axios_success} 
        />
        <Dialog 
            header={title} 
            visible={displayBasic} 
            modal 
            style={{ width:'80vw'}}
            onHide={() => setDisplayBasic(false)}
        >
            <JMT_FIELD_LABEL id="PalletID" value={pallet_id} caption="PalletID"/>
            <JMT_FIELD_TEXTAREA id="Note" value={Note} setter={setNote} caption="Note"/>
            <br/>
            <Button label={title} className="p-button-raised p-button-sm p-button-warning mr-2 mb-2" onClick={handleCreate} />
            <br/>
            <DataTable 
                        style={{padding: '0px'}}  
                        value={data} 
                        dataKey="LineID" 
                        rowHover 
                        showGridlines
                        size="small"
                        responsiveLayout="scroll"
                    >
                        <Column header="Logtime" field="Logtime" />
                        <Column header="UserName" field="UserName" />
                        <Column header="Note" field="Note" />
                    </DataTable>
        </Dialog>
        <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}
