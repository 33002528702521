import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_LABEL                } from '../../components/jmt_field_label.jsx';
import { JMT_PDF_DIALOG                 } from '../../components/jmt_pdf_dialog';
import { JMT_FIELD_DATEBOX              } from '../../components/jmt_field_datebox';

import { POPUP_PALLET_LOOKUP            } from './lookup';
import { POPUP_PALLET_ADD               } from './pallet_add';
import { POPUP_PALLET_MOVE              } from './pallet_move';
import { POPUP_PALLET_EMPTY             } from './pallet_empty';
import { POPUP_PALLET_LINE_ADD          } from './pallet_line_add';
import { POPUP_PALLET_LINE_UPDATE       } from './pallet_line_update';
import { POPUP_PALLET_LINE_DELETE       } from './pallet_line_delete';
import { POPUP_PALLET_NOTE_ADD          } from './pallet_note_add';
import { POPUP_PALLET_EXCEPTIONS        } from './pallet_exceptions'
import { POPUP_PALLET_CREATE_IBT        } from './pallet_create_ibt'


export const Page_Pallet = () => {
    const localSName = "RBLF_PalletID";
    const initID = localStorage.getItem(localSName) != null ? localStorage.getItem(localSName) : 0;
    const [PalletID, setPalletID] = useState(initID);
    const [ReportDate, setReportDate] = useState(new Date()); 

    const [axios_trigger, set_axios_trigger] = useState("");
    const [axios_loading, set_axios_loading] = useState(false);
    const [axios_data, set_axios_data] = useState({
        "header":[], 
        "detail":[],
        "pallet_lines_less_than_qty_1": [],
        "pallets_with_no_lines": [],
        "pallet_lines_without_batch_nos": [],
        "pallet_lines_without_expiry_date": [],
        "pallets_with_expired_stock": [],
        "pallets_with_no_location": [],
        "pallets_with_no_branch": [],
    });

    const handleRefresh = () => {
        set_axios_trigger(new Date());
        set_axios_loading(true);
    }

    const axios_success = (res) => {
        set_axios_data(res.data);
        set_axios_loading(false);
    }

    useEffect(() => {
        localStorage.setItem(localSName, PalletID);
        handleRefresh()
    }, [PalletID]);

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }
    const { basicOptions, horizontalOptions, multiAxisOptions, stackedOptions } = getLightTheme();

    return (
        <div className="grid">
            <AXIOS 
                endpoint='/pallet/get/read' 
                payload={{
                    "pallet_id":PalletID,
                    "branch": "JHB",
                }} 
                trigger={axios_trigger} 
                handleSuccess={axios_success} 
            />
            <div className="col-12 lg:col-8">
                <Panel header="Pallet">
                    <div className="grid">
                        <div className="col-12 lg:col-6">
                            <POPUP_PALLET_LOOKUP id="PalletID" value={PalletID} setter={setPalletID} caption="PalletID"/>
                            <JMT_FIELD_LABEL id="PalletType" value={axios_data.header[0]?.PalletType} caption="PalletType"/>
                            <JMT_FIELD_LABEL id="LocationID" value={axios_data.header[0]?.LocationID} caption="LocationID"/>
                            <JMT_FIELD_LABEL id="LocationType" value={axios_data.header[0]?.LocationType} caption="LocationID"/>
                            <JMT_FIELD_LABEL id="CreationDate" value={axios_data.header[0]?.CreationDate} caption="CreationDate"/>
                            <JMT_FIELD_LABEL id="CreatedBy" value={axios_data.header[0]?.CreatedBy} caption="CreatedBy"/>
                            <br/>
                            <POPUP_PALLET_ADD setter={setPalletID}/>
                            <POPUP_PALLET_MOVE pallet_id={PalletID} location_id={axios_data.header[0]?.LocationID} refresh={handleRefresh}/>
                            <POPUP_PALLET_EMPTY pallet_id={PalletID} lineCount={axios_data.detail.length} refresh={handleRefresh}/>
                            {/* <pre>{JSON.stringify(axios_data, null, 2) }</pre> */}
                        </div>
                        <div className="col-12 lg:col-6">
                            <JMT_FIELD_LABEL id="ShipmentNo" value={axios_data.header[0]?.ShipmentNo} caption="ShipmentNo"/>
                            <JMT_FIELD_LABEL id="ReceiveBookNo" value={axios_data.header[0]?.ReceiveBookNo} caption="ReceiveBookNo"/>
                            <JMT_FIELD_LABEL id="PastelGRNID" value={axios_data.header[0]?.PastelGRNID} caption="PastelGRNID"/>
                            <JMT_FIELD_LABEL id="IBTID" value={axios_data.header[0]?.IBTID} caption="IBTID"/>
                            <JMT_FIELD_LABEL id="Branch" value={axios_data.header[0]?.Branch} caption="Branch"/>
                        </div>
                    </div>
                    <br/>
                    <POPUP_PALLET_CREATE_IBT/>
                </Panel>
                <br/>

                <Panel header="Detail">
                    <JMT_PDF_DIALOG title="Pallet Sheet" endpoint="/pallet/pdf/sheet" payload={{"pallet_id": PalletID}}/>
                    <br/>
                    <DataTable 
                        style={{padding: '0px'}}  
                        value={axios_data.detail} 
                        loading={axios_loading} 
                        dataKey="LineID" 
                        rowHover 
                        showGridlines
                        size="small"
                        responsiveLayout="scroll"
                    >
                        <Column header="PalletLineID" field="PalletLineID" />
                        <Column header="StockCode" field="StockCode" />
                        <Column header="Description" field="Description" />
                        <Column header="Qty" field="Qty" />
                        <Column header="BatchNo" field="BatchNo" />
                        <Column header="ExpiryDate" field="ExpiryDate" />
                        <Column header="UPD" body={(rowdata)=>{return <POPUP_PALLET_LINE_UPDATE pallet_id={PalletID} data={rowdata} status = {rowdata.Status} refresh={handleRefresh}/>}}/>
                        <Column header="DEL" body={(rowdata)=>{return <POPUP_PALLET_LINE_DELETE pallet_id={PalletID} data={rowdata} status = {rowdata.Status} refresh={handleRefresh}/>}}/>
                    </DataTable>
                    <br/>
                    <POPUP_PALLET_LINE_ADD pallet_id={PalletID} refresh={handleRefresh}/>
                </Panel>
                <br/>
                <Panel header="Pallet History">
                    <POPUP_PALLET_NOTE_ADD pallet_id={PalletID} data={axios_data.history} refresh={handleRefresh}/>
                    <br/>
                    <DataTable 
                        style={{padding: '0px'}}  
                        value={axios_data.history} 
                        loading={axios_loading} 
                        dataKey="LineID" 
                        rowHover 
                        showGridlines
                        size="small"
                        responsiveLayout="scroll"
                    >
                        <Column header="Logtime" field="Logtime" />
                        <Column header="UserName" field="UserName" />
                        <Column header="Note" field="Note" />
                    </DataTable>
                </Panel>
            </div>
            <div className="col-12 lg:col-4">
                <Panel header="Reports">
                    <POPUP_PALLET_EXCEPTIONS/>
                    <JMT_PDF_DIALOG title="Pallet Expired Stock" endpoint="/pallet/raw/pdf/expiry" payload={{}}/>
                    <br/>
                    <h3>Date Based Reports</h3>
                    <JMT_FIELD_DATEBOX id="ReportDate" value={ReportDate} setter={setReportDate} caption="ReportDate" />
                    <br/>
                </Panel>
            </div>
            <div className="col-12 lg:col-4">
                <Panel header="Exceptions">
                    <h3>Pallet lines less than qty 1</h3>
                    <Chart type="bar" data={axios_data.pallet_lines_less_than_qty_1} options={stackedOptions} />
                    <h3>Pallets with no lines</h3>
                    <Chart type="bar" data={axios_data.pallets_with_no_lines} options={stackedOptions} />
                    <h3>Palletlines without batch nos</h3>
                    <Chart type="bar" data={axios_data.pallet_lines_without_batch_nos} options={stackedOptions} />
                    <h3>Pallet lines without expiry date</h3>
                    <Chart type="bar" data={axios_data.pallet_lines_without_expiry_date} options={stackedOptions} />
                    <h3>Pallets with expired stock</h3>
                    <Chart type="bar" data={axios_data.pallets_with_expired_stock} options={stackedOptions} />
                    <h3>Pallets with no location</h3>
                    <Chart type="bar" data={axios_data.pallets_with_no_location} options={stackedOptions} />
                    <h3>Pallets with no branch</h3>
                    <Chart type="bar" data={axios_data.pallets_with_no_branch} options={stackedOptions} />
                </Panel>
            </div>
        </div>
    );
}
