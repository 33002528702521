import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { AXIOS                          } from '../../components/axios'
import { JMT_FIELD_TEXTBOX              } from '../../components/jmt_field_textbox.jsx';
import { JMT_API_BUTTON                 } from '../../components/jmt_api_button';

export const POPUP_ROLE_CREATE = ({ setter }) => {
    const endpoint = "/role/set/create";
    const title = "Create Role"

    const [displayBasic, setDisplayBasic] = useState(false);
    const [triggerCreate, setTriggerCreate] = useState("");
    
    const [RoleName, setRoleName] = useState("");
    const [Description, setDescription] = useState("");

    const handlePopup = () => {
        setDisplayBasic(true);
    }

    const handleClick = () => {
        setTriggerCreate(new Date());
    }

    const handleCreateSuccess = (res) => {
        setter(RoleName);
        setRoleName("");
        setDisplayBasic(false);
    }

    return (
        <>
            <AXIOS 
                endpoint={endpoint} 
                payload={{
                    "role_name":RoleName, 
                    "description": Description
                }} 
                trigger={triggerCreate} 
                handleSuccess={handleCreateSuccess} 
            />
            <Dialog 
                header={title}
                visible={displayBasic} 
                modal 
                position={"top"} 
                onHide={() => setDisplayBasic(false)}
            >
                <JMT_FIELD_TEXTBOX id="RoleName" value={RoleName} setter={setRoleName} caption="RoleName" unit=""/>
                <JMT_FIELD_TEXTBOX id="Description" value={Description} setter={setDescription} caption="Description" />
                <br/>
                <Button label={title} onClick={handleClick} className="p-button-sm p-button-warning mr-2 mb-2"/>
            </Dialog>
            <JMT_API_BUTTON caption={title} api={endpoint} onclick={handlePopup}/>
        </>
    );
}

